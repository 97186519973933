<template>
  <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
    <div class="content-card">
    <!-- <paginationTable /> -->
    <div class="page-header-content">
          <h4 class="page-header-title">{{ $t('message.homePageTitle') }}</h4>
        </div>
    <!-- <p v-if="user"> {{user.id}}</p>
    <p v-if="user"> {{user.name}}</p>
    <p v-if="user"> {{user.email }}</p>
    <p v-if="user"> {{user.phone_number }}</p>
    <p v-if="user"> {{user.identity_number }}</p>
    <p v-if="user"> {{user.role }}</p>
    <p v-if="user"> {{user.permissions }}</p> -->
  




  </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axiosInstance from '../axios-config';

// import paginationTable from '../components/table.vue'
export default {
  name: 'HomeView',
  components: {
    // paginationTable,
  },
  data(){
    return{
      selected: this.$i18n.locale,
        options: [
          { item: 'ar', name: 'ar' },
          { item: 'en', name: 'en' },
        ],
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale === 'ar';
    },
    // جلب بيانات المستخدم من Vuex store
    ...mapGetters(['user'])
  },
  methods:{
    switchLanguage(){
      console.log(11111);
      this.$i18n.locale = this.selected;
      const currentPath = this.$route.path;
      const newPath = currentPath.replace(/^\/(ar|en)/, `/${this.selected}`);
      this.$router.push(newPath);
    },
  },
  
}
</script>
<style scoped>

.page-header-content {
  padding-right: 20px;
  padding-left: 20px;
}

.page-header-title {
  margin-bottom: .5rem;
}

</style>
