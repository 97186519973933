<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
      <div class="content-card">
      <h1 class="test-main">{{ 'Viewing Real Estate Units'}}</h1>
  
  
    </div>
    </div>
  </template>

<script>




export default {
    name: 'viewProperty',
    props: ['id'],
    data() {
        return {
           
        };
    },
    computed: {
    isRTL() {
      return this.$i18n.locale === 'ar';
    }
  },
}
</script>

<style scoped>

</style>