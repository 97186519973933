import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import NotFound from '../views/NotFound.vue';
import ForgetPassword from '../views/ForgetPassword.vue';
import AddAssociations from '../views/AddAssociations.vue';
import EditAssociation from '../views/EditAssociation.vue';
import AssociationsList from '../views/AssociationsList.vue';
import ModifyPropertyData from '../views/ModifyPropertyData.vue';
import AssociationRealEstate from '../views/AssociationRealEstate.vue';
import createNewProperty from '../views/createNewProperty.vue';
import ViewPropertyData from '../views/ViewPropertyData.vue';
import RealEstateManagement from '../views/RealEstateManagement/RealEstateManagement.vue';
import createProperty from '../views/RealEstateManagement/createProperty.vue';
import ModifyProperty from '../views/RealEstateManagement/ModifyProperty.vue';
import RealEstateUnits from '../views/RealEstateUnits.vue';
import createNewRealStateUnit from '../views/createNewRealStateUnit.vue';
import modifyRealStateUnitData from '../views/modifyRealStateUnitData.vue';
import AddNewUser from '../views/AddNewUser.vue';
import ModifyUser from '../views/ModifyUser.vue';
import RealEstateUnitManagement from '../views/RealEstateUnitManagement.vue';
import ShowUsers from '../views/ShowUsers.vue';
import RolesAndPermissions from '../views/RolesAndPermissions.vue';
import ModifyUserRole from '../views/ModifyUserRole.vue';



import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/ar',
    name: 'home-ar',
    component: HomeView,
    meta: { requiresAuth: true } // login required to entre
  },
  {
    path: '/en',
    name: 'home-en',
    component: HomeView,
    meta: { requiresAuth: true } // login required to entre
  },
  {
    path: '/ar/login',
    name: 'login-ar',
    component: LoginView,
    meta: { requiresAuth: false }
  },
  {
    path: '/en/login',
    name: 'login-en',
    component: LoginView,
    meta: { requiresAuth: false }
  },
  {
    path: '/ar/forget-password',
    name: 'ForgetPassword-ar',
    component: ForgetPassword,
    meta: { requiresAuth: false }
  },
  {
    path: '/en/forget-password',
    name: 'ForgetPassword-en',
    component: ForgetPassword,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/AddAssociations',
    name: 'AddAssociations-ar',
    component: AddAssociations,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/AddAssociations',
    name: 'AddAssociations-en',
    component: AddAssociations,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/EditAssociation/:id',
    name: 'EditAssociation-ar',
    component: EditAssociation,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/EditAssociation/:id',
    name: 'EditAssociation-en',
    component: EditAssociation,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/AssociationsList',
    name: 'AssociationsList-en',
    component: AssociationsList,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/AssociationsList',
    name: 'AssociationsList-ar',
    component: AssociationsList,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/ModifyPropertyData/:id',
    name: 'ModifyPropertyData-en',
    component: ModifyPropertyData,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/ModifyPropertyData/:id',
    name: 'ModifyPropertyData-ar',
    component: ModifyPropertyData,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/AssociationRealEstate/:id',
    name: 'AssociationRealEstate-en',
    component: AssociationRealEstate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/AssociationRealEstate/:id',
    name: 'AssociationRealEstate-ar',
    component: AssociationRealEstate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/createNewProperty/:id',
    name: 'createNewProperty-en',
    component: createNewProperty,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/createNewProperty/:id',
    name: 'createNewProperty-ar',
    component: createNewProperty,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/ViewPropertyData/:id',
    name: 'ViewPropertyData-en',
    component: ViewPropertyData,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/ViewPropertyData/:id',
    name: 'ViewPropertyData-ar',
    component: ViewPropertyData,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/RealEstateUnits/:id',
    name: 'RealEstateUnits-en',
    component: RealEstateUnits,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/RealEstateUnits/:id',
    name: 'RealEstateUnits-ar',
    component: RealEstateUnits,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/createNewRealStateUnit/:id',
    name: 'createNewRealStateUnit-en',
    component: createNewRealStateUnit,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/createNewRealStateUnit/:id',
    name: 'createNewRealStateUnit-ar',
    component: createNewRealStateUnit,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/modifyRealStateUnitData/:id',
    name: 'modifyRealStateUnitData-en',
    component: modifyRealStateUnitData,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/modifyRealStateUnitData/:id',
    name: 'modifyRealStateUnitData-ar',
    component: modifyRealStateUnitData,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/AddNewUser',
    name: 'AddNewUser-en',
    component: AddNewUser,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/AddNewUser',
    name: 'AddNewUser-ar',
    component: AddNewUser,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/ModifyUser/:id',
    name: 'ModifyUser-en',
    component: ModifyUser,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/ModifyUser/:id',
    name: 'ModifyUser-ar',
    component: ModifyUser,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/RealEstateUnitManagement',
    name: 'RealEstateUnitManagement-en',
    component: RealEstateUnitManagement,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/RealEstateUnitManagement',
    name: 'RealEstateUnitManagement-ar',
    component: RealEstateUnitManagement,
    meta: { requiresAuth: true }
    
  },
  {
    path: '/en/RealEstateManagement',
    name: 'RealEstateManagement-en',
    component: RealEstateManagement,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/RealEstateManagement',
    name: 'RealEstateManagement-ar',
    component: RealEstateManagement,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/RealEstateManagement/createProperty',
    name: 'createProperty-en',
    component: createProperty,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/RealEstateManagement/createProperty',
    name: 'createProperty-ar',
    component: createProperty,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/RealEstateManagement/ModifyProperty/:id',
    name: 'ModifyProperty-en',
    component: ModifyProperty,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/RealEstateManagement/ModifyProperty/:id',
    name: 'ModifyProperty-ar',
    component: ModifyProperty,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/ShowUsers',
    name: 'ShowUsers-en',
    component: ShowUsers,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/ShowUsers',
    name: 'ShowUsers-ar',
    component: ShowUsers,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/RolesAndPermissions',
    name: 'RolesAndPermissions-en',
    component: RolesAndPermissions,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/RolesAndPermissions',
    name: 'RolesAndPermissions-ar',
    component: RolesAndPermissions,
    meta: { requiresAuth: true }
  },
  {
    path: '/en/ModifyUserRole/:id',
    name: 'ModifyUserRole-en',
    component: ModifyUserRole,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/ar/ModifyUserRole/:id',
    name: 'ModifyUserRole-ar',
    component: ModifyUserRole,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/',
    redirect: '/ar/login'
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export function createRouter(i18n) {
  router.beforeEach((to, from, next) => {
    // إزالة '/' من نهاية المسار إذا كان موجودًا
    if (to.path !== '/' && to.path.endsWith('/')) {
      next({ path: to.path.slice(0, -1), query: to.query });
      return;
    }

    const lang = to.path.split('/')[1];
    if (['ar', 'en'].includes(lang)) {
      i18n.locale = lang;
    } else {
      i18n.locale = 'en';
    }

    // تحقق من حالة تسجيل الدخول
    const isAuthenticated = !!localStorage.getItem('token');

    // إذا كان المستخدم مسجلاً وحاول الوصول إلى صفحة تسجيل الدخول
    if (to.path == `/${lang}/login` && isAuthenticated) {
      next({ path: `/${lang}` }); // إعادة توجيه إلى الصفحة الرئيسية
    } else {
      // تحقق من حالة تسجيل الدخول للمسارات التي تتطلب ذلك
      const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
      if (requiresAuth && !isAuthenticated) {
        next(`/${lang}/login`);
      } else {
        next();
      }
    }
  });
  return router;
}

