<template>
  <div class="sidebar" :class="{'sidebar-rtl':  $i18n.locale === 'ar', 'sidebar-ltr':  $i18n.locale === 'en'}">
    <!-- محتوى الـ sidebar -->
    <main id="content" role="main" class="main">

      <!-- start header bg for absolute header -->
      <!-- <div class="main__header__bg">
    <div class="content container" style="height: 22rem;"></div>
  </div> -->
      <!-- end header bg for absolute header -->

      <!-- start main website contens -->
      <!-- <div class="content container mb-3" style="margin-top: -20rem;"> -->

      <!-- Navbar Vertical -->
      <div class="navbar-expand-lg">
        <button class="navbar-toggler sidebar-toggle text-white border-white-10 mb-3"  type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarVerticalNavMenu" aria-controls="navbarVerticalNavMenu" aria-expanded="false"
          aria-label="Toggle navigation" :class="{'sidebar-toggle-rtl':  $i18n.locale === 'ar', 'sidebar-toggle-ltr':  $i18n.locale === 'en'}">
          <span class="d-flex justify-content-between align-items-center">
            <span class="navbar-toggler-text text-white"></span>
            
            <span id="sidebarList" class="navbar-toggler-default" >
              <i class="bi-list"></i>
            </span>
            <span class="navbar-toggler-toggled" >
              <i id="sidebarX" class="bi-x"></i>
            </span>
          </span>
        </button>



        <aside id="navbarVerticalNavMenu"
          class="js-navbar-vertical-aside navbar navbar-vertical navbar-vertical-absolute navbar-vertical-detached navbar-shadow navbar-collapse collapse bg-white rounded-2"
          :class="{'sidebar-rtl': !isSidebarOpen && $i18n.locale === 'ar', 'sidebar-ltr': !isSidebarOpen && $i18n.locale !== 'ar'}"
          >
          <button type="button" v-if="showSidebarButton"
            class="btn-navbar-aside-toggler-custom js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler">
            <span class="open" @click="closesidebar()">
              <svg style="width: 18px;height: 18px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                id="Arrow-Right-1--Streamline-Ultimate">
                <path d="m5.651 23.5 12.576 -11.126a0.5 0.5 0 0 0 0 -0.748L5.651 0.5" fill="none" stroke="#fff"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></path>
              </svg>
            </span>
            <span class="close" @click="opensidebar()">
              <svg style="width: 18px;height: 18px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                id="Arrow-Left-1--Streamline-Ultimate">
                <path d="M18.4 0.5 5.825 11.626a0.5 0.5 0 0 0 0 0.748L18.4 23.5" fill="none" stroke="#fff"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></path>
              </svg>
            </span>
          </button>
          <div class="navbar-vertical-container">
            <div class="navbar-vertical-footer-offset">
              <!-- Content -->
              <div class="navbar-vertical-content">
                <div id="navbarVerticalMenu" class="main__website__sidebar nav nav-pills nav-vertical card-navbar-nav">
                  <div id="navbarVerticalMenuPagesMenu">
                    <div class="nav-item">
                      <router-link class="nav-link" :to="'/' + this.$i18n.locale">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/gmzxduhd.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                          <!-- <img :src="require('@/assets/wired-outline-63-home.gif')" class="sidebar__icon" style="width:25px;height:25px" > -->
                        <span class="nav-link-title" :class="[{ 'nav-item-rtl': isRTL, 'nav-item-ltr': !isRTL }]">{{$t('message.home')}} </span>
                      </router-link>
                    </div>
                    <!-- <div class="nav-item" @mouseover="handleHover('navbarVerticalMenuPagesUsersMenu', true)" @mouseleave="handleHover('navbarVerticalMenuPagesUsersMenu', false)">
                      <a class="nav-link dropdown-toggle " href="#navbarVerticalMenuPagesUsersMenu" role="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuPagesUsersMenu"
                        aria-expanded="false" aria-controls="navbarVerticalMenuPagesUsersMenu">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/imamsnbq.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title">{{$t('message.members')}}</span>
                      </a>

                      <div id="navbarVerticalMenuPagesUsersMenu" class="nav-collapse collapse " :class="[{ 'nav-collapse-rtl': isRTL, 'nav-collapse-ltr': !isRTL }]"
                        data-bs-parent="#navbarVerticalMenuPagesMenu">
                        <a class="nav-link" href="add-user.html">{{$t('message.createNewAccount')}}</a>
                        <a class="nav-link" href="clients-list.html">{{$t('message.membersList')}}</a>
                      </div>
                    </div> -->
                    
                    <div class="nav-item" @mouseover="handleHover('navbarVerticalMenuPagesUsersMenu', true)" @mouseleave="handleHover('navbarVerticalMenuPagesUsersMenu', false)">
                      <a class="nav-link dropdown-toggle " href="#navbarVerticalMenuPagesUsersMenu" role="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuPagesUsersMenu"
                        aria-expanded="false" aria-controls="navbarVerticalMenuPagesUsersMenu">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/imamsnbq.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title">{{ 'المستخدمين' }}</span>
                      </a>

                      <div id="navbarVerticalMenuPagesUsersMenu" class="nav-collapse collapse " :class="[{ 'nav-collapse-rtl': isRTL, 'nav-collapse-ltr': !isRTL }]"
                        data-bs-parent="#navbarVerticalMenuPagesMenu">
                        <router-link class="nav-link" :to="'/' + this.$i18n.locale + '/ShowUsers'" >{{ 'عرض المستخدمين' }}</router-link>
                        <router-link class="nav-link" :to="'/' + this.$i18n.locale + '/AddNewUser'" >{{ 'إنشاء مستخدم جديد' }}</router-link>
                      </div>
                    </div>

                    
                    <div class="nav-item"  @mouseover="handleHover('navbarVerticalMenuAddAssociations', true)" @mouseleave="handleHover('navbarVerticalMenuAddAssociations', false)">
                      <a class="nav-link dropdown-toggle" href="#navbarVerticalMenuAddAssociations" role="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuAddAssociations"
                        aria-expanded="false" aria-controls="navbarVerticalMenuAddAssociations">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/xxduqotz.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title">{{$t('message.associationManagement')}}</span>
                      </a>

                      <div id="navbarVerticalMenuAddAssociations" class="nav-collapse collapse" :class="[{ 'nav-collapse-rtl': isRTL, 'nav-collapse-ltr': !isRTL }]"
                        data-bs-parent="#navbarVerticalMenuPagesMenu">
                        <router-link class="nav-link" :to="'/' + this.$i18n.locale + '/AddAssociations'" >{{ $t('message.addAssociations') }}</router-link>
                        <router-link class="nav-link" :to="'/' + this.$i18n.locale + '/AssociationsList'" >{{ $t('message.associationsList') }}</router-link>
                      </div>
                    </div>
                    <!-- <div class="nav-item" @mouseover="handleHover('navbarVerticalMenuPagesUserProfileMenu', true)" @mouseleave="handleHover('navbarVerticalMenuPagesUserProfileMenu', false)">
                      <a class="nav-link dropdown-toggle" href="#navbarVerticalMenuPagesUserProfileMenu" role="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuPagesUserProfileMenu"
                        aria-expanded="false" aria-controls="navbarVerticalMenuPagesUserProfileMenu">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/eszyyflr.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title">مشرفي الجمعيات</span>
                      </a>

                      <div id="navbarVerticalMenuPagesUserProfileMenu" class="nav-collapse collapse " :class="[{ 'nav-collapse-rtl': isRTL, 'nav-collapse-ltr': !isRTL }]"
                        data-bs-parent="#navbarVerticalMenuPagesMenu">
                        <a class="nav-link" href="add-associations-adminstration.html">انشاء حساب مشرف</a>
                        <a class="nav-link" href="list-associations-adminstration.html">قائمة المشرفين</a>
                      </div>
                    </div> -->

                    <div class="nav-item">
                      <router-link class="nav-link" :to="'/' + this.$i18n.locale + '/RealEstateManagement'">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/gmzxduhd.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title" :class="[{ 'nav-item-rtl': isRTL, 'nav-item-ltr': !isRTL }]">إدارة العقارات</span>
                      </router-link>
                    </div>

                    <!-- <div class="nav-item" @mouseover="handleHover('navbarVerticalMenuAddcontract', true)" @mouseleave="handleHover('navbarVerticalMenuAddcontract', false)">
                      <a class="nav-link dropdown-toggle" href="#navbarVerticalMenuAddcontract" role="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuAddcontract" aria-expanded="false"
                        aria-controls="navbarVerticalMenuAddcontract">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/wzwygmng.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title">ادارة العقود</span>
                      </a>

                      <div id="navbarVerticalMenuAddcontract" class="nav-collapse collapse " :class="[{ 'nav-collapse-rtl': isRTL, 'nav-collapse-ltr': !isRTL }]"
                        data-bs-parent="#navbarVerticalMenuPagesMenu">
                        <a class="nav-link" href="add-contract.html">انشاء عقد جديد</a>
                        <a class="nav-link" href="contract-list.html">قائمة العقود</a>
                      </div>
                    </div> -->
                    
                    <div class="nav-item">
                      <router-link class="nav-link" :to="'/' + this.$i18n.locale + '/RealEstateUnitManagement'">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/gmzxduhd.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title" :class="[{ 'nav-item-rtl': isRTL, 'nav-item-ltr': !isRTL }]">ادارة الوحدات العقارية </span>
                      </router-link>
                    </div>
                    
  


                    <!-- <div class="nav-item" @mouseover="handleHover('navbarVerticalMenuservices', true)" @mouseleave="handleHover('navbarVerticalMenuservices', false)">
                      <a class="nav-link dropdown-toggle" href="#navbarVerticalMenuservices" role="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuservices" aria-expanded="false"
                        aria-controls="navbarVerticalMenuservices">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/sbiheqdr.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title">الخدمات</span>
                      </a>
                      <div id="navbarVerticalMenuservices" class="nav-collapse collapse " :class="[{ 'nav-collapse-rtl': isRTL, 'nav-collapse-ltr': !isRTL }]"
                        data-bs-parent="#navbarVerticalMenuPagesMenu">
                        <a class="nav-link" href="add-maintenance.html">اضافة بلاغ صيانة</a>
                        <a class="nav-link" href="open-maintenance-list.html">قائمة بلاغات الصيانة</a>
                        <a class="nav-link" href="add-purchases.html">اضافة مشتريات</a>
                        <a class="nav-link" href="purchases-list.html">قائمة المشتريات</a>
                      </div>
                    </div> -->
                    <!-- <div class="nav-item">
                      <a class="nav-link" href="add-disclosure-statement.html">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/uukerzzv.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title" :class="[{ 'nav-item-rtl': isRTL, 'nav-item-ltr': !isRTL }]">بيان الافصاح</span>
                      </a>
                    </div> -->
                    <!-- <div class="nav-item" @mouseover="handleHover('navbarVerticalMenuPageslaterMenu', true)" @mouseleave="handleHover('navbarVerticalMenuPageslaterMenu', false)">
                      <a class="nav-link dropdown-toggle" href="#navbarVerticalMenuPageslaterMenu" role="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuPageslaterMenu"
                        aria-expanded="false" aria-controls="navbarVerticalMenuPageslaterMenu">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/imamsnbq.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title">ادارة الاتصالات </span>
                      </a>

                      <div id="navbarVerticalMenuPageslaterMenu" class="nav-collapse collapse " :class="[{ 'nav-collapse-rtl': isRTL, 'nav-collapse-ltr': !isRTL }]"
                        data-bs-parent="#navbarVerticalMenuPagesMenu">
                        <a class="nav-link" href="add-letter.html">انشاء خطاب جديد</a>
                        <a class="nav-link" href="letter-list.html">قائمة الخطابات</a>
                        <a class="nav-link" href="contact-users.html">التواصل الداخلي مع الملاك</a>
                        <a class="nav-link" href="external-communication.html">التواصل الخارجي مع المزودين</a>
                        
                      </div>
                    </div> -->
                    <!-- <div class="nav-item" @mouseover="handleHover('navbarVerticalMenuPagesvotesMenu', true)" @mouseleave="handleHover('navbarVerticalMenuPagesvotesMenu', false)">
                      <a class="nav-link dropdown-toggle" href="#navbarVerticalMenuPagesvotesMenu" role="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuPagesvotesMenu"
                        aria-expanded="false" aria-controls="navbarVerticalMenuPagesvotesMenu">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/aoucpuvt.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title">ادارة الاجتمعات و القرارات </span>
                      </a>

                      <div id="navbarVerticalMenuPagesvotesMenu" class="nav-collapse collapse " :class="[{ 'nav-collapse-rtl': isRTL, 'nav-collapse-ltr': !isRTL }]"
                        data-bs-parent="#navbarVerticalMenuPagesMenu">
                        <a class="nav-link" href="add-voting.html">انشاء تصويت جديد</a>
                        <a class="nav-link" href="voting-list.html">قائمة التصويتات</a>
                        <a class="nav-link" href="voting-progress.html">مشاهدة التصويت</a>
                        <a class="nav-link" href="voting-invitation.html">دعوة الى اجتماع</a>
                        <a class="nav-link" href="meeting-results.html">محضر الاجتماع </a>
                      </div>
                    </div> -->

                    <!-- <div class="nav-item" @mouseover="handleHover('navbarVerticalMenuPagescasesMenu', true)" @mouseleave="handleHover('navbarVerticalMenuPagescasesMenu', false)">
                      <a class="nav-link dropdown-toggle" href="#navbarVerticalMenuPagescasesMenu" role="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuPagescasesMenu"
                        aria-expanded="false" aria-controls="navbarVerticalMenuPagescasesMenu">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/xleflqfa.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title">الادارة القانونية</span>
                      </a>

                      <div id="navbarVerticalMenuPagescasesMenu" class="nav-collapse collapse " :class="[{ 'nav-collapse-rtl': isRTL, 'nav-collapse-ltr': !isRTL }]"
                        data-bs-parent="#navbarVerticalMenuPagesMenu">
                        <a class="nav-link" href="add-case.html">انشاء قضية جديدة</a>
                        <a class="nav-link" href="cases-list.html">قائمة القضايا</a>
                        <a class="nav-link" href="case-details.html">مشاهدة قضية</a>
                      </div>
                    </div> -->




                    <!-- <div class="nav-item" @mouseover="handleHover('navbarVerticalMenuPagesfacteurMenu', true)" @mouseleave="handleHover('navbarVerticalMenuPagesfacteurMenu', false)">
                      <a class="nav-link dropdown-toggle " href="#navbarVerticalMenuPagesfacteurMenu" role="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuPagesfacteurMenu"
                        aria-expanded="false" aria-controls="navbarVerticalMenuPagesUserProfileMenu">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/jjjmlddk.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title">الادارة الماليه و البنكية</span>
                      </a>

                      <div id="navbarVerticalMenuPagesfacteurMenu" class="nav-collapse collapse" :class="[{ 'nav-collapse-rtl': isRTL, 'nav-collapse-ltr': !isRTL }]"
                        data-bs-parent="#navbarVerticalMenuPagesMenu">
                        <a class="nav-link" href="banking.html">الحساب البنكي</a>
                        <a class="nav-link" href="add-invoices.html">انشاء فاتورة جديدة</a>

                        <div class="nav-item" @mouseover="handleHover('navbarVerticalMenuPagesfacteurinnerMenu', true)" @mouseleave="handleHover('navbarVerticalMenuPagesfacteurinnerMenu', false)">
                          <a class="nav-link dropdown-toggle" href="#navbarVerticalMenuPagesfacteurinnerMenu"
                            role="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarVerticalMenuPagesfacteurinnerMenu" aria-expanded="false"
                            aria-controls="navbarVerticalMenuPagesfacteurinnerMenu">
                            <span class="nav-link-title">الفواتير و المدفوعات</span>
                          </a>

                          <div id="navbarVerticalMenuPagesfacteurinnerMenu" class="nav-collapse collapse" :class="[{ 'nav-collapse-rtl': isRTL, 'nav-collapse-ltr': !isRTL }]"
                            data-bs-parent="#navbarVerticalMenuPagesfacteurMenu">
                            <a class="nav-link" href="invoices-list.html">قائمة الفواتير</a>
                            <a class="nav-link" href="payed-invoices-list.html">قائمة المدفوعات</a>
                          </div>
                        </div>

                        <a class="nav-link" href="add-receipt.html">انشاء سند جديد</a>
                        <a class="nav-link" href="receipt-a-list.html">سندات القبض</a>
                        <a class="nav-link" href="receipt-b-list.html">سندات الصرف</a>
                      </div>
                    </div> -->
                    <!-- <div class="nav-item">
                      <a class="nav-link" href="report-management.html">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/gqdnbnwt.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title" :class="[{ 'nav-item-rtl': isRTL, 'nav-item-ltr': !isRTL }]">ادارة التقارير</span>
                      </a>
                    </div> -->

                    <!-- <div class="nav-item" @mouseover="handleHover('navbarVerticalMenuPagescarsMenu', true)" @mouseleave="handleHover('navbarVerticalMenuPagescarsMenu', false)">
                      <a class="nav-link dropdown-toggle " href="#navbarVerticalMenuPagescarsMenu" role="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuPagescarsMenu"
                        aria-expanded="false" aria-controls="navbarVerticalMenuPagesUserProfileMenu">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/wvaaucoa.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title">ادارة الحركة</span>
                      </a>

                      <div id="navbarVerticalMenuPagescarsMenu" class="nav-collapse collapse" :class="[{ 'nav-collapse-rtl': isRTL, 'nav-collapse-ltr': !isRTL }]"
                        data-bs-parent="#navbarVerticalMenuPagesMenu">
                        <a class="nav-link" href="add-car.html">اضافة سيارة</a>
                        <a class="nav-link" href="list-cars.html">قائمة السيارات</a>
                        <a class="nav-link" href="list-parking.html">فهرس المواقف</a>
                      </div>
                    </div> -->

                    <!-- <span class="dropdown-header mt-2">الصلاحيات و الاشعارات</span>
                    <small class="bi-three-dots nav-subtitle-replacer"></small> -->


                    <!-- <div class="nav-item">
                      <a class="nav-link" href="#">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/tvyxmjyo.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title" :class="[{ 'nav-item-rtl': isRTL, 'nav-item-ltr': !isRTL }]">صلاحيات المستخدمين</span>
                      </a>
                    </div> -->
                    <!-- <div class="nav-item">
                      <a class="nav-link" href="#">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/dqunxaob.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title" :class="[{ 'nav-item-rtl': isRTL, 'nav-item-ltr': !isRTL }]">الاشعارات</span>
                      </a>
                    </div> -->


                    <!-- <span class="dropdown-header mt-2">الاعدادات</span>
                    <small class="bi-three-dots nav-subtitle-replacer"></small> -->

                    <!-- <div class="nav-item" @mouseover="handleHover('navbarVerticalMenuPagesAccountMenu', true)" @mouseleave="handleHover('navbarVerticalMenuPagesAccountMenu', false)">
                      <a class="nav-link dropdown-toggle " href="#navbarVerticalMenuPagesAccountMenu" role="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuPagesAccountMenu"
                        aria-expanded="false" aria-controls="navbarVerticalMenuPagesAccountMenu">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/tdrtiskw.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title">الأدوار والصلاحيات</span>
                      </a>

                      <div id="navbarVerticalMenuPagesAccountMenu" class="nav-collapse collapse " :class="[{ 'nav-collapse-rtl': isRTL, 'nav-collapse-ltr': !isRTL }]"
                        data-bs-parent="#navbarVerticalMenuPagesMenu">
                        <a class="nav-link " href="#">سجل تحديث النظام</a>
                        <a class="nav-link " href="#">الربط والتكامل API</a>
                        <a class="nav-link " href="#">إعدادات الشركة</a>
                        <a class="nav-link " href="#">إدارة الأملاك</a>
                        <a class="nav-link " href="#">إدارة بلاغات الصيانة</a>
                        <a class="nav-link " href="#">إعدادات الفواتير</a>
                        <a class="nav-link " href="#">إعدادات الضرائب</a>
                        <a class="nav-link " href="#">إدارة اللغة </a>
                      </div>
                    </div> -->

                    <div class="nav-item"  @mouseover="handleHover('navbarVerticalMenuPagesAccountMenu', true)" @mouseleave="handleHover('navbarVerticalMenuPagesAccountMenu', false)">
                      <a class="nav-link dropdown-toggle" href="#navbarVerticalMenuPagesAccountMenu" role="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuPagesAccountMenu"
                        aria-expanded="false" aria-controls="navbarVerticalMenuPagesAccountMenu">
                        <lord-icon target=".nav-link" class="sidebar__icon" src="https://cdn.lordicon.com/xxduqotz.json"
                          trigger="hover" colors="primary:#121331,secondary:#edd100"
                          style="width:25px;height:25px"></lord-icon>
                        <span class="nav-link-title">{{'الإعدادات'}}</span>
                      </a>

                      <div id="navbarVerticalMenuPagesAccountMenu" class="nav-collapse collapse" :class="[{ 'nav-collapse-rtl': isRTL, 'nav-collapse-ltr': !isRTL }]"
                        data-bs-parent="#navbarVerticalMenuPagesMenu">
                        <router-link class="nav-link" :to="'/' + this.$i18n.locale + '/RolesAndPermissions'" >{{ 'الأدوار والصلاحيات' }}</router-link>
                      </div>
                    </div>
                  </div>
                  <!-- End Content -->

                  <!-- Footer -->
                  <div class="navbar-vertical-footer">
                    <ul class="navbar-vertical-footer-list">
                      <span class="system__version"><span>النسخة:</span> <b>1.0.0</b></span>
                      <li class="navbar-vertical-footer-list-item">
                        <!-- Other Links -->
                        <div class="dropdown dropup">
                          <button type="button" class="btn btn-ghost-secondary btn-icon rounded-circle"
                            id="otherLinksDropdown" data-bs-toggle="dropdown" aria-expanded="false"
                            data-bs-dropdown-animation="">
                            <i class="bi-info-circle"></i>
                          </button>

                          <div class="dropdown-menu navbar-dropdown-menu-borderless"
                            aria-labelledby="otherLinksDropdown">
                            <span class="dropdown-header">تحتاج الى مساعدة ؟</span>
                            <a class="dropdown-item" href="#">
                              <i class="bi-journals dropdown-item-icon"></i>
                              <span class="text-truncate" title="">مركز المعلومات</span>
                            </a>
                            <a class="dropdown-item" href="#">
                              <i class="bi-info-circle dropdown-item-icon"></i>
                              <span class="text-truncate" title="">مركز الدعم</span>
                            </a>
                          </div>
                        </div>
                        <!-- End Other Links -->
                      </li>
                    </ul>
                  </div>
                  <!-- End Footer -->
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>
      <!-- End Navbar Vertical -->


      <!-- </div> -->
      <!-- end main website contens -->




    </main>

  </div>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
  name: "sidebar",
  data() {
    return {
      isSidebarOpen: true,
      showListIcon: true,
      showXIcon: false,
      windowWidth: window.innerWidth
    }
  },
  watch: {
    '$route'() {
      this.updateContentClass();
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale === 'ar';
    },
    showSidebarButton() {
      return this.windowWidth > 1024;
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
    closesidebar() {
      this.isSidebarOpen = false;
      document.body.classList.add('navbar-vertical-aside-mini-mode');
      this.updateContentClass();
    },
    opensidebar() {
      this.isSidebarOpen = true;
      document.body.classList.remove('navbar-vertical-aside-mini-mode');
      this.updateContentClass();
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    updateContentClass() {
      const content = document.getElementById('main-content');
      const footer = document.getElementById('footer');
      if (this.isSidebarOpen) {
        if (this.$i18n.locale === 'ar') {
          content.classList.remove('full-content-rtl');
          content.classList.add('content-rtl');
          footer.classList.remove('full-content-rtl');
          footer.classList.add('content-rtl');
        } else {
          content.classList.remove('full-content-ltr');
          content.classList.add('content-ltr');
          footer.classList.remove('full-content-ltr');
          footer.classList.add('content-ltr');
        }
      } else {
        if (this.$i18n.locale === 'ar') {
          content.classList.add('full-content-rtl');
          content.classList.remove('content-rtl');
          footer.classList.add('full-content-rtl');
          footer.classList.remove('content-rtl');
        } else {
          content.classList.add('full-content-ltr');
          content.classList.remove('content-ltr');
          footer.classList.add('full-content-ltr');
          footer.classList.remove('content-ltr');
        }
      }
    },
    handleHover(id, show) {
      if (document.body.classList.contains('navbar-vertical-aside-mini-mode')) {
        const menu = document.getElementById(id);
        if (show) {
          menu.classList.add('show');
        } else {
          menu.classList.remove('show');
        }
      }
    },
    handleRouteChange() {
      if (window.innerWidth <= 768) {
        const sidebar = document.getElementById('navbarVerticalNavMenu');
        if (sidebar.classList.contains('show')) {
          sidebar.classList.remove('show');
          this.showListIcon = false;
          this.showXIcon = true;
        }else{
          this.showListIcon = true;
          this.showXIcon = true;
        }
      }
    }
    
  },
  created() {
    console.log("Current locale:", this.$i18n.locale);
  },
  mounted() {
    this.updateContentClass();
  }
}
</script>

<style scoped>
@import '../assets/css/theme.min.css';
@import '../assets/css/sidebar.css';
@import '../assets/css/rtl.css?v=1.0';

.sidebar {
  display: flex !important;
  flex-direction: column;
}

.dropdown-toggle::after {
  border-top: none !important;
}
.nav-item .nav-collapse {
  transition: all 0.3s ease;
}

.nav-item .nav-collapse.show {
  display: block !important;
}
</style>
