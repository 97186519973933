<template>
    <div>
      <realStateUnitForm :createUnitId="parsedUnitId" />
    </div>
  </template>

<script>

import realStateUnitForm from '../components/realStateUnitForm.vue';



export default {
    props: ['id'],
    components: {
        realStateUnitForm
    },
    data() {
        return {
           
        };
    },
    computed: {
        parsedUnitId() {
            return parseInt(this.id);
        }
    }
}
</script>