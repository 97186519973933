import { render, staticRenderFns } from "./RealEstateUnits.vue?vue&type=template&id=6c042e52&scoped=true"
import script from "./RealEstateUnits.vue?vue&type=script&lang=js"
export * from "./RealEstateUnits.vue?vue&type=script&lang=js"
import style0 from "./RealEstateUnits.vue?vue&type=style&index=0&id=6c042e52&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c042e52",
  null
  
)

export default component.exports