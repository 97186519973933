// store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
  },
  mutations: {
    setUser(state, userData) {
      state.user = userData;
    },
    setToken(state, token) {
      state.token = token;
    },
    clearAuthData(state) {
      state.user = null;
      state.token = null;
    },
  },
  actions: {
    async login({ commit }, authData) {
      console.log('authData', authData);
      
      commit('setUser', authData);
      commit('setToken', authData.token);
      localStorage.setItem('token', authData.token);
      localStorage.setItem('user', JSON.stringify(authData)); // تأكد من تخزين بيانات المستخدم

    },
    logout({ commit }) {
      commit('clearAuthData');
      localStorage.removeItem('token');
      localStorage.removeItem('user'); // حذف بيانات المستخدم

    },
    // إضافة إجراء لتحميل بيانات المستخدم من localStorage عند بدء التطبيق
    loadUser({ commit }) {
      const user = localStorage.getItem('user');
      const token = localStorage.getItem('token');
      if (user) {
        commit('setUser', JSON.parse(user));
      }
      if (token) {
        commit('setToken', token);
      }
    }
  },
  getters: {
    isAuthenticated(state) {
      return state.token !== null;
    },
    user(state) {
      return state.user;
    }
  }
});
