<template>
    <div>
      <propertyForm :associationId="parsedAssociationId" />
    </div>
  </template>

<script>

import propertyForm from '../components/propertyForm.vue';



export default {
    props: ['id'],
    components: {
        propertyForm
    },
    data() {
        return {
           
        };
    },
    computed: {
        parsedAssociationId() {
            return parseInt(this.id);
        }
    }
}
</script>