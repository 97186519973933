import axios from 'axios';
import store from './store';
import { i18n } from './main';
import router from './router';
import iziToast from 'izitoast';

const instance = axios.create({
  baseURL: 'https://admin-demo.edarat365.com/api/',
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  const currentLanguage = i18n.locale;

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  config.headers['Accept-Language'] = currentLanguage;

  return config;
}, error => {
  return Promise.reject(error);
});


instance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    // مسح بيانات المستخدم من localStorage و Vuex
    store.dispatch('logout');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  
    // إظهار إشعار انتهاء صلاحية الجلسة
    iziToast.error({
      title: 'Unauthenticated',
      message: 'Your session has expired. Please log in again.',
      position: 'bottomLeft',
      timeout: 5000,
    });
    setTimeout(() => {
      window.location.reload();
    }, 3000); // تأخير 3 ثوانٍ
  }

  return Promise.reject(error);
});


export default instance;
