<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
        <div class="content-card">
            <div class="page-header">
                <div class="row mb-3">
                    <div class="col-sm mb-2 mb-sm-0">
                        <div class="d-flex gap-2 align-items-center mb-3">
                            <h1 class="page-header-title mb-0">{{ 'قائمة المستخدمين' }}</h1>
                        </div>
                        <p class="page-header-text">{{'سوف تجد هنا قائمة بجميع المستخدمين'}}</p>
                    </div>
                </div>
                <!-- End Row -->
            </div>
            <div class="row justify-content-end mb-3" v-if="selectedCount > 0">
                <div class="col-lg">
                    <!-- Datatable Info -->
                    <div id="datatableCounterInfo">
                        <div class="d-sm-flex justify-content-lg-end align-items-sm-center">
                            <span class="d-block d-sm-inline-block fs-5 me-3 mb-2 mb-sm-0">
                                {{$t('message.selectedLists')}} {{ ': ' }}<span id="datatableCounter">{{ selectedCount }}</span>
                            </span>
                            <a class="btn btn-outline-danger btn-sm mb-2 mb-sm-0 me-2" @click="deleteSelected">
                                <i class="bi-trash"></i> {{$t('message.delete')}}
                            </a>
                        </div>
                    </div>
                    <!-- End Datatable Info -->
                </div>
            </div>

            <div class="card">
                <!-- Header -->
                <div class="card-header card-header-content-md-between bg-light jadda-pattern-dark">
                    <div class="mb-md-0 col-md-4">
                        <div class="input-group input-group-merge input-group-flush">
                            <div class="input-group-prepend input-group-text">
                                <lord-icon colors="primary:#121331,secondary:#edd100" target=".input-group"
                                    src="https://cdn.lordicon.com/iszohckt.json" trigger="hover"
                                    style="width:15px;height:15px"> </lord-icon>
                            </div>
                            <b-form-input v-model="filter" :placeholder="'البحث فى قائمة المستخدمين'"
                                class="mt-3 mb-3 form-control bg-white filter-search-input"></b-form-input>
                            <!-- <input id="datatableSearch" type="search" class="form-control bg-white" placeholder="البحث في قائمة الجمعيات" aria-label="Search users"> -->
                        </div>
                    </div>
                    <!-- End Search -->

                    <div class="d-grid d-sm-flex gap-2">
                        <div class="dropdown mb-2 mb-md-0">
                            <button @click="createNewUser()" class="btn btn-logo add-new-property">{{'إضافه مستخدم'}}</button>
                        </div>
                        <div class="dropdown">
                            <button type="button" class="btn btn-white show-lists w-100" id="showHideDropdown"
                                data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                <i class="bi-table me-1"></i> {{ $t('message.lists') }} <span
                                    class="badge bg-soft-dark text-dark rounded-circle ms-1">{{ this.fields.length }}</span>
                            </button>

                            <div class="dropdown-menu dropdown-menu-end dropdown-card"
                                aria-labelledby="showHideDropdown" style="width: 15rem;">
                                <div class="card card-sm">
                                    <div class="card-body">
                                    <div class="d-grid gap-3 w-100">
                                        <b-form-checkbox v-for="field in fields" :disabled="field.key === 'select'"
                                                        :key="field.key" v-model="field.visible" @change="updateVisibleFields">
                                        <span style="margin: 5px">{{ field.label }}</span>
                                        </b-form-checkbox>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Dropdown -->
                    </div>
                </div>
            <!-- {{filteredItems}} -->
            
            <b-table hover id="my-table" :busy.sync="isBusy" responsive :items="filteredItems" :fields="visibleFields" :current-page="1" :per-page="perPage"
                    :filter="filter" @filtered="onFiltered" @sort-changed="onSortChanged">
                    
                    <template #table-busy>
                        <div class="text-center text-success  my-2">
                        <!-- <b-spinner class="align-middle"></b-spinner> -->
                        <!-- <strong>Loading...</strong> -->
                        <img :src="require('@/assets/icon-edarat365.png')" width="40" height="40" alt="Logo" class="loading-icon">
                        </div>
                    </template>

                    <template #head(select)="data">
                        <b-form-checkbox v-model="selectAll" @change="toggleSelectAll"></b-form-checkbox>
                    </template>

                    <!-- {{this.items.forEach(item => {item.selected = this.selectAll;})}} -->

                    <template #cell(select)="row">
                        <b-form-checkbox v-model="row.item.selected"></b-form-checkbox>
                    </template>
                    <template #cell(roles)="row">
                        <b-form-checkbox v-model="row.item.selected"></b-form-checkbox>
                    </template>

                    <template #cell(actions)="row">
                        <!-- <b-dropdown size="sm" text="Actions" variant="link">
                        <b-dropdown-item @click="enableEdit(row.item)">Edit</b-dropdown-item>
                        <b-dropdown-item @click="deleteItem(row.item)">Delete</b-dropdown-item>
                    </b-dropdown> -->
                        <div class="btn-group" role="group">
                            <a class="btn btn-white view-btn btn-sm" href="#">
                                <i class="bi-eye me-1"></i> {{ $t('message.view') }}
                            </a>

                            <!-- Button Group -->
                            <div class="btn-group">
                                <button type="button"
                                    class="btn btn-white btn-icon btn-sm dropdown-toggle dropdown-toggle-empty"
                                    id="productsEditDropdown1" data-bs-toggle="dropdown" aria-expanded="false"></button>

                                <div class="dropdown-menu dropdown-menu-end mt-1"
                                    aria-labelledby="productsEditDropdown1">
                                    <!-- <a class="dropdown-item" @click="handleUnitClick(row.item)">
                                        <i class="bi bi-house-add dropdown-item-icon"></i> {{ $t('message.realEstateUnits') }}
                                    </a> -->
                            
                                    <a class="dropdown-item" @click="handleEditClick(row.item)">
                                        <i class="bi-pencil-fill dropdown-item-icon"></i> {{ 'تعديل بيانات المستخدم' }}
                                    </a>
                                    <a class="dropdown-item" @click="enableDelete(row.item)"  data-bs-toggle="modal"
                                        data-bs-target="#delete-modal">
                                        <i class="bi-trash dropdown-item-icon"></i> {{ $t('message.delete') }}
                                    </a>
                                </div>
                            </div>
                            <!-- End Button Group -->
                        </div>
                    </template>


                </b-table>

                <!-- delete Modal -->
                <div class="modal fade" id="delete-modal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="exampleModalLabel">{{ $t('message.deleteData') }}</h4>
                                <button type="button" class="btn-close" :class="[{ 'btn-close-rtl': isRTL, 'btn-close-ltr': !isRTL }]" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p class="page-header-text"> {{ $t('message.deleteDataParagraph') }}</p>
                                <input type="text" v-model="inputText" class="form-control" placeholder="سبب الحذف" @input="validateInput">
                                <small v-if="showError" class="text-danger">يجب أن يحتوي الإدخال على 50 حرفًا على الأقل وأن يكون حروفًا فقط.</small>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-white" data-bs-dismiss="modal">{{$t('message.CancelButton')}}</button>
                                <button type="button"  @click="deleteItemConfirmed" :disabled="!isValid" class="btn btn-danger">{{$t('message.delete')}}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <template v-if="filteredItems.length === 0 && filter.length > 0">
                    <div class="text-center p-4">
                        <lord-icon src="https://cdn.lordicon.com/wkppeqfv.json"
                            colors="primary:#121331,secondary:#edd100" trigger="loop" style="width:100px;height:100px">
                        </lord-icon>
                        <p class="mb-0"> {{ $t('message.noMatchingResults') }} "{{ filter }}".</p>
                    </div>
                </template>


                <div class="d-flex  align-items-center  card-header card-header-content-md-between bg-light jadda-pattern-dark pagination-section">
                    <ul class="mb-0" style="padding-left: 5px;">
                        <b-form-group>
                            <b-form-select
                            id="perPageSelect"
                            class="perPageSelect"
                            v-model="perPage"
                            :options="perPageOptions"
                            @change="onPerPageChange"
                            ></b-form-select>
                        </b-form-group>
                    </ul>
                    
                    <b-pagination
                        class="mb-0"
                        style="padding-right: 0px !important;"
                        :total-rows="totalRows"
                        v-model="currentPage"
                        :per-page="perPage"
                        @change="changePage"
                        prev-text="Previous"
                        next-text="Next"
                    ></b-pagination>
                </div>


            </div>
            <!-- end card -->
        </div>
    </div>
</template>

<script>
import axiosInstance from '../axios-config';


export default {
    data() {
        return {
            items: [],
            fields: [
                { key: 'select', label: '', visible: true },
                { key: 'identity_number', label: 'رقم الهوية', visible: true },
                { key: 'name', label: 'الاسم', visible: true },
                { key: 'email', label: 'البريد الإلكتروني', visible: true },
                { key: 'phone_number', label: 'رقم الهاتف', visible: true },
                { key: 'role', label: 'الدور', visible: true },
                { key: 'actions', label: this.$t('message.actions'), visible: true }
            ],
            currentPage: '',
            perPage: 10,
            perPageOptions: [1, 2 , 5, 10, 20, 50, 100],
            totalRows: 0,
            isBusy: false,
            filter: '',
            selectAll: false,
            inputText: '',
            showError: false,
            isValid: false,
        };
    },
    watch: {
        // selectAll(newValue) {
        //     this.items.forEach(item => {
        //         item.selected = newValue;
        //     });
        // }
        // items(){
        //     console.log('isEdited');
            
        // }
    },
    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
        visibleFields() {
            return this.fields.filter(field => field.visible);
        },
        filteredItems() {
            console.log('Filtering items...'); // Check if it's being triggered
            return this.items.filter(item => {
                return this.visibleFields.some(field => {
                    return item[field.key] && item[field.key].toString().toLowerCase().includes(this.filter.toLowerCase());
                });
            });
        },
        selectedCount() {
            console.log('1111',this.items.filter(item => item.selected));
            
            const itemSelectLingth = this.items.filter(item => item.selected);
            // console.log('1111',itemSelectLingth.length);
            return itemSelectLingth.length
        },
    },
    methods: {
        onFiltered(filteredItems) {            
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        updateVisibleFields() {
            this.visibleFields = this.fields.filter(field => field.visible);
        },
        toggleSelectAll() {
            this.items.forEach((item, index) => {
            // استخدم Vue.set لضمان أن التحديث تفاعلي
            this.$set(this.items, index, { ...item, selected: this.selectAll });
            });
        },
        enableDelete(item) {
            this.deleteItem = item; // تخزين العنصر الذي سيتم حذفه
        },
        deleteItemConfirmed() {
            this.items = this.items.filter(i => i.id !== this.deleteItem.id); // حذف العنصر
            this.deleteItem = {}; // إعادة تعيين العنصر المحذوف
            $('#delete-modal').modal('hide'); // إخفاء المودال
        },
        deleteSelected() {
            this.items = this.items.filter(item => !item.selected);
            this.selectAll = false;
        },
        onSortChanged(context) {
            console.log('Sort Changed', context);
        },
        createNewUser() {
            this.$nextTick(() => {
            this.$router.push('/' + this.$i18n.locale + '/AddNewUser');
            });
        },
        handleEditClick(item) {
            this.$nextTick(() => {
            this.$router.push('/' + this.$i18n.locale + '/ModifyUser/' + item.id);
            });
        },
        
        validateInput() {
           // إزالة المسافات من النص لحساب الطول بدون المسافات
            const cleanedText = this.inputText.replace(/\s/g, '');
            // Regex يتحقق من أن النص يحتوي فقط على الأحرف الإنجليزية أو العربية
            const regex = /^[\u0600-\u06FFa-zA-Z]+$/;
            this.isValid = regex.test(cleanedText) && cleanedText.length >= 50;
            this.showError = !this.isValid;
        },
        async featchData() {
            this.isBusy = true;
            try {
                const response = await axiosInstance.get('users',{
                    params: {
                        page: this.currentPage,
                        paginated: this.perPage,
                    }
                });


                console.log('users data:', response.data.data);
                
                // this.items = [];
                // this.items = response.data.data;
                
                this.items = [...response.data.data.map(item => {
                    item.selected = false;
                    return item;
                })];
                // this.$refs.table.refresh();
                
                console.log('dataInTale = ' ,this.items);
                //this.$refs.table.refresh()
                this.totalRows = response.data.meta.total;
                // setTimeout(() => {
                    this.currentPage = response.data.meta.current_page;
                // }, 10000);

                


                this.perPage = response.data.meta.per_page;
                console.log('this.currentPage',this.currentPage);
                console.log('this.perPage',this.perPage);
                console.log('this.totalRows',this.totalRows);
    
                this.isBusy = false;


            } catch (error) {
                console.error('Login error:', error);
                this.isBusy = false
            }
        },
        changePage(page) {              
            this.currentPage = page;
            this.featchData();
            // setInterval(() => {
            //     this.$root.$emit('bv::refresh::table', 'my-table')
            //     if (this.$root.$emit('bv::refresh::table', 'my-table')) {
            //         console.log('refresh');
            //         console.log('this.currentPage',this.currentPage);
            //         console.log('this.perPage',this.perPage);
            //         console.log('this.totalRows',this.totalRows);    
            //         console.log(this.items);
                                    
            //     }
            // }, 2000);
            
        },
        onPerPageChange() {
            this.currentPage = 1;
            this.featchData();
        },
    },
    mounted() {
        // this.totalRows = this.items.length;
    },
    created() {
        this.featchData();
    }
};
</script>

<style scoped>


.bg-light {
    background-color: rgba(249, 250, 252, 1) !important;
    min-height: 100%;
}

.jadda-pattern-dark {
    background-image: url(../assets/pattren-dark.svg);
    background-size: 150px;
}

.card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
    /* color: var(--bs-card-cap-color); */
    /* background-color: var(--bs-card-cap-bg); */
    border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
}

.card-header:first-child {
    border-radius: 0.6875rem 0.6875rem 0 0;
}

.page-header-content {
    padding-right: 20px;
    padding-left: 20px;
}

.page-header-title {
    margin-bottom: .5rem;
}

.page-header-text {
    margin-bottom: .25rem;
    color: #677788;
    font-size: 14px
}


/* .dropdown-menu.show {
    overflow: scroll !important;
    height: 300px !important;
} */
</style>