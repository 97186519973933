<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
        <div class="content-card">
            <div class="card-header bg-light jadda-pattern-dark">
                <div class="page-header-content">
                    <h4 class="page-header-title">{{ $t('message.realEstateUnits') }}</h4>
                    <p class="page-header-text">{{ $t('message.realEstateUnitsManagementParagraph') }}</p>
                </div>
            </div>
            <!-- <div class="container"> -->
            <div class="row">
                <div class="col-md-3" :class="[{ 'ps-md-0-rtl': isRTL, 'ps-md-0-ltr': !isRTL }]">
                    <div class="stepper-wrapper bg-light">
                        <div class="stepper d-flex flex-column">
                            <div v-for="step in steps" :key="step.id"
                                :class="['stepper-item', { 'completed': step.completed, 'active': step.id === currentStep }]">
                                <div class="step-counter">{{ step.id }}</div>
                                <div class="step-name">{{ step.title }}</div>
                                <div class="step-name">{{ step.content }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9" :class="[{ 'pe-md-0-rtl': isRTL, 'pe-md-0-ltr': !isRTL }]">
                    <div class="step-content">
                        <div v-if="currentStep === 1">
                            <!-- محتوى الخطوة 1 -->
                            <h5 class="mb-0">{{ $t('message.basicInformationAboutRealEstateUnit') }}</h5>
                            <p>{{ $t('message.manageBasicInformationAboutRealEstateUnit') }}</p>

                            <div class="mt-5">
                                <div class="row justify-content-start">
                                    <div class="mb-3 col-md-3 col-6">
                                        <div>
                                            <label for="associationSelect"
                                                class="form-label">{{ $t('message.associationSelect') }}</label>
                                            <v-select :dir="direction" v-model="form.selectedAssociationId" :options="associations"
                                                :class="{ 'is-invalid': errors.selectedAssociationId }" 
                                                :placeholder="$t('message.associationSelect')"
                                                id="multiple-select-custom-field"></v-select>
                                            <div class="invalid-feedback" v-if="errors.selectedAssociationId">{{ errors.selectedAssociationId
                                                }}</div>
                                        </div>
                                    </div>

                                    <div class="mb-3 col-md-3 col-6">
                                        <div>
                                            <label for="propertyNumberSelect"
                                                class="form-label">{{ $t('message.propertyNumberSelect') }}</label>
                                            <v-select :dir="direction" v-model="form.propertyNumberSelect" :options="associations"
                                                :class="{ 'is-invalid': errors.propertyNumberSelect }" 
                                                :placeholder="$t('message.propertyNumberSelect')"
                                                id="multiple-select-custom-field"></v-select>
                                            <div class="invalid-feedback" v-if="errors.propertyNumberSelect">{{ errors.propertyNumberSelect
                                                }}</div>
                                        </div>
                                    </div>


                                    <div class="mb-3 col-md-3 col-6">
                                        <label for="unitNumber" class="form-label">{{ 'رقم الوحدة العقارية' }}</label>
                                        <input type="text" placeholder='رقم الوحدة العقارية'
                                            :class="{ 'is-invalid': errors.unitNumber }"
                                            class="form-control form-control-sm" id="unitNumber"
                                            v-model="form.unitNumber" @blur="validateField('unitNumber')"
                                            @focus="clearError('unitNumber')" required>
                                        <div class="invalid-feedback" v-if="errors.unitNumber">{{ errors.unitNumber }}
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-3 col-6">
                                        <label for="descriptionUnit" class="form-label">{{ 'وصف الوحدة العقارية'
                                            }}</label>
                                        <input type="text" placeholder='وصف الوحدة العقارية'
                                            :class="{ 'is-invalid': errors.descriptionUnit }"
                                            class="form-control form-control-sm" id="descriptionUnit"
                                            v-model="form.descriptionUnit" @blur="validateField('descriptionUnit')"
                                            @focus="clearError('descriptionUnit')" required>
                                        <div class="invalid-feedback" v-if="errors.descriptionUnit">{{
        errors.descriptionUnit }}</div>
                                    </div>

                                    <div class="mb-3 col-md-3 col-6">
                                        <label for="space" class="form-label">{{ $t('message.area') }}</label>
                                        <input type="text" :placeholder="$t('message.area')"
                                            :class="{ 'is-invalid': errors.space }" class="form-control form-control-sm"
                                            id="space" v-model="form.space" @blur="validateField('space')"
                                            @focus="clearError('space')" required>
                                        <div class="invalid-feedback" v-if="errors.space">{{ errors.space }}</div>
                                    </div>

                                    <div class="mb-3 col-md-3 col-6">
                                        <label for="unitCode" class="form-label">{{ 'كود الوحدة' }}</label>
                                        <input type="number" placeholder='كود الوحدة'
                                            :class="{ 'is-invalid': errors.unitCode }"
                                            class="form-control form-control-sm" id="unitCode" v-model="form.unitCode"
                                            @blur="validateField('unitCode')" @focus="clearError('unitCode')" required>
                                        <div class="invalid-feedback" v-if="errors.unitCode">{{ errors.unitCode }}</div>
                                    </div>

                                    <!-- <div class="mb-3 col-md-3 col-6">
                                    <label for="ownershipercentage" class="form-label">{{'نسبه تملكة' }}</label>
                                    <input type="number" placeholder= 'نسبه تملكة'
                                        :class="{ 'is-invalid': errors.ownershipercentage }"
                                        class="form-control form-control-sm" id="ownershipercentage"
                                        v-model="form.ownershipercentage" @blur="validateField('ownershipercentage')"
                                        @focus="clearError('ownershipercentage')" required>
                                    <div class="invalid-feedback" v-if="errors.ownershipercentage">{{errors.ownershipercentage }}</div>
                                </div> -->

                                    <div class="mb-3 col-md-3 col-6">
                                        <label for="ownedSpace" class="form-label">{{ 'المساحة المملوكة' }}</label>
                                        <input type="number" placeholder='المساحة المملوكة'
                                            :class="{ 'is-invalid': errors.ownedSpace }"
                                            class="form-control form-control-sm" id="ownedSpace"
                                            v-model="form.ownedSpace" @blur="validateField('ownedSpace')"
                                            @focus="clearError('ownedSpace')" required>
                                        <div class="invalid-feedback" v-if="errors.ownedSpace">{{ errors.ownedSpace }}
                                        </div>
                                    </div>

                                    <div class="mb-3 col-md-3 col-6">
                                        <label for="FloorNumber" class="form-label">{{ 'رقم الطابق' }}</label>
                                        <input type="number" placeholder='رقم الطابق'
                                            :class="{ 'is-invalid': errors.FloorNumber }"
                                            class="form-control form-control-sm" id="FloorNumber"
                                            v-model="form.FloorNumber" @blur="validateField('FloorNumber')"
                                            @focus="clearError('FloorNumber')" required>
                                        <div class="invalid-feedback" v-if="errors.FloorNumber">{{ errors.FloorNumber }}
                                        </div>
                                    </div>

                                    <div class="mb-3 col-md-3 col-6">
                                        <label for="bathroomsNumber" class="form-label">{{ 'عدد الحمامات' }}</label>
                                        <input type="number" placeholder='عدد الحمامات'
                                            :class="{ 'is-invalid': errors.bathroomsNumber }"
                                            class="form-control form-control-sm" id="bathroomsNumber"
                                            v-model="form.bathroomsNumber" @blur="validateField('bathroomsNumber')"
                                            @focus="clearError('bathroomsNumber')" required>
                                        <div class="invalid-feedback" v-if="errors.bathroomsNumber">{{
        errors.bathroomsNumber }}
                                        </div>
                                    </div>


                                    <div class="mb-3 col-md-3 col-6">
                                        <label for="bedroomsNumber" class="form-label">{{ 'عدد غرف النوم' }}</label>
                                        <input type="number" placeholder='عدد غرف النوم'
                                            :class="{ 'is-invalid': errors.bedroomsNumber }"
                                            class="form-control form-control-sm" id="bedroomsNumber"
                                            v-model="form.bedroomsNumber" @blur="validateField('bedroomsNumber')"
                                            @focus="clearError('bedroomsNumber')" required>
                                        <div class="invalid-feedback" v-if="errors.bedroomsNumber">{{
        errors.bedroomsNumber
    }}</div>
                                    </div>


                                    <div class="mb-3 col-md-3 col-6">
                                        <label for="northBoundary" class="form-label">{{ $t('message.northernBoundary')
                                            }}</label>
                                        <input type="text" :placeholder="$t('message.northernBoundary')"
                                            :class="{ 'is-invalid': errors.northBoundary }"
                                            class="form-control form-control-sm" id="northBoundary"
                                            v-model="form.northBoundary" @blur="validateField('northBoundary')"
                                            @focus="clearError('northBoundary')" required>
                                        <div class="invalid-feedback" v-if="errors.northBoundary">{{
        errors.northBoundary }}
                                        </div>
                                    </div>

                                    <div class="mb-3 col-md-3 col-6">
                                        <label for="southBoundary" class="form-label">{{ $t('message.southernBoundary')
                                            }}</label>
                                        <input type="text" :placeholder="$t('message.southernBoundary')"
                                            :class="{ 'is-invalid': errors.southBoundary }"
                                            class="form-control form-control-sm" id="southBoundary"
                                            v-model="form.southBoundary" @blur="validateField('southBoundary')"
                                            @focus="clearError('southBoundary')" required>
                                        <div class="invalid-feedback" v-if="errors.southBoundary">{{
        errors.southBoundary }}
                                        </div>
                                    </div>

                                    <div class="mb-3 col-md-3 col-6">
                                        <label for="eastBoundary" class="form-label">{{ $t('message.eastBoundary')
                                            }}</label>
                                        <input type="text" :placeholder="$t('message.eastBoundary')"
                                            :class="{ 'is-invalid': errors.eastBoundary }"
                                            class="form-control form-control-sm" id="eastBoundary"
                                            v-model="form.eastBoundary" @blur="validateField('eastBoundary')"
                                            @focus="clearError('eastBoundary')" required>
                                        <div class="invalid-feedback" v-if="errors.eastBoundary">{{ errors.eastBoundary
                                            }}</div>
                                    </div>

                                    <div class="mb-3 col-md-3 col-6">
                                        <label for="westBoundary" class="form-label">{{ $t('message.westBoundary')
                                            }}</label>
                                        <input type="text" :placeholder="$t('message.westBoundary')"
                                            :class="{ 'is-invalid': errors.westBoundary }"
                                            class="form-control form-control-sm" id="westBoundary"
                                            v-model="form.westBoundary" @blur="validateField('westBoundary')"
                                            @focus="clearError('westBoundary')" required>
                                        <div class="invalid-feedback" v-if="errors.westBoundary">{{ errors.westBoundary
                                            }}</div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div v-if="currentStep === 2">
                            <h5 class="mb-0">{{ $t('message.realEstateUnitManagement') }}</h5>
                            <p>{{ $t('message.realEstateManagementOptions') }}</p>

                            <div class="mt-3 stepTwoContent">
                                <div class="row">
                                    <div class="mb-3 col-md-9 col-6">
                                        <div>
                                            <label for="legalOwnership" class="form-label">{{ 'اسم المالك' }}</label>
                                            <v-select :dir="direction" v-model="form.legalOwnership" :options="managers"
                                                :class="{ 'is-invalid': errors.legalOwnership }" multiple
                                                :placeholder="$t('message.selectLegalOwnership')"
                                                id="multiple-select-custom-field"></v-select>
                                            <div class="invalid-feedback" v-if="errors.legalOwnership">{{
        errors.legalOwnership
    }}</div>
                                        </div>
                                    </div>

                                    <div class="mb-3 col-md-3 col-6">
                                        <button type="button" class="btn btn-success addUserBtn btn-sm"
                                            data-bs-toggle="modal" data-bs-target="#addnewusermodel">
                                            {{ $t('message.AddNewOwner') }}
                                        </button>

                                        <div class="modal fade " id="addnewusermodel" tabindex="-1"
                                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered scrollable">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h4 class="modal-title" id="exampleModalLabel">
                                                            {{ $t('message.AddNewOwner') }}</h4>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">

                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="ownerName" class="form-label">{{
        $t('message.ownerName') }}</label>
                                                            <input type="text" :placeholder="$t('message.ownerName')"
                                                                :class="{ 'is-invalid': errors.ownerName }"
                                                                class="form-control form-control-sm" id="ownerName"
                                                                v-model="form.ownerName"
                                                                @blur="validateField('ownerName')"
                                                                @focus="clearError('ownerName')" required>
                                                            <div class="invalid-feedback" v-if="errors.ownerName">
                                                                {{ errors.ownerName }}</div>
                                                        </div>
                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="nationalId" class="form-label">{{
        $t('message.idNumber') }}</label>
                                                            <input type="text" placeholder="xxxxxxxxxxxxxx"
                                                                :class="{ 'is-invalid': errors.nationalId }"
                                                                class="form-control form-control-sm" id="nationalId"
                                                                v-model="form.nationalId"
                                                                @blur="validateField('nationalId')"
                                                                @focus="clearError('nationalId')" required>
                                                            <div class="invalid-feedback" v-if="errors.nationalId">
                                                                {{ errors.nationalId }}</div>
                                                        </div>
                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="propertyType" class="form-label">{{
        $t('message.PropertyType') }}</label>
                                                            <select v-model="form.propertyType"
                                                                :class="{ 'is-invalid': errors.propertyType }"
                                                                class="form-control form-control-sm" id="propertyType"
                                                                @blur="validateField('propertyType')"
                                                                @focus="clearError('propertyType')" required>
                                                                <option value="" disabled selected>
                                                                    {{ $t('message.selectPropertyType') }}</option>
                                                                <option v-for="property in propertyTypes"
                                                                    :key="property" :value="property">{{ property }}
                                                                </option>
                                                            </select>
                                                            <div class="invalid-feedback" v-if="errors.propertyType">
                                                                {{ errors.propertyType }}</div>
                                                        </div>
                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="blockNumber" class="form-label">{{
        $t('message.PropertyNumber') }}</label>
                                                            <input type="number"
                                                                :placeholder="$t('message.PropertyNumber')"
                                                                :class="{ 'is-invalid': errors.blockNumber }"
                                                                class="form-control form-control-sm" id="blockNumber"
                                                                v-model="form.blockNumber"
                                                                @blur="validateField('blockNumber')"
                                                                @focus="clearError('blockNumber')" required>
                                                            <div class="invalid-feedback" v-if="errors.blockNumber">
                                                                {{ errors.blockNumber }}</div>
                                                        </div>
                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="spaceModal" class="form-label">{{
        $t('message.area') }}</label>
                                                            <input type="text" :placeholder="$t('message.area')"
                                                                :class="{ 'is-invalid': errors.spaceModal }"
                                                                class="form-control form-control-sm" id="spaceModal"
                                                                v-model="form.spaceModal"
                                                                @blur="validateField('spaceModal')"
                                                                @focus="clearError('spaceModal')" required>
                                                            <div class="invalid-feedback" v-if="errors.spaceModal">
                                                                {{ errors.spaceModal }}</div>
                                                        </div>
                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="instrumentNumber" class="form-label">{{
        $t('message.deedNumber') }}</label>
                                                            <input type="number" :placeholder="$t('message.deedNumber')"
                                                                :class="{ 'is-invalid': errors.instrumentNumber }"
                                                                class="form-control form-control-sm"
                                                                id="instrumentNumber" v-model="form.instrumentNumber"
                                                                @blur="validateField('instrumentNumber')"
                                                                @focus="clearError('instrumentNumber')" required>
                                                            <div class="invalid-feedback"
                                                                v-if="errors.instrumentNumber">{{ errors.instrumentNumber
                                                                }}</div>
                                                        </div>
                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="sourceInstrument" class="form-label">{{
                                                                $t('message.deedSource') }}</label>
                                                            <input type="number" :placeholder="$t('message.deedSource')"
                                                                :class="{ 'is-invalid': errors.sourceInstrument }"
                                                                class="form-control form-control-sm"
                                                                id="sourceInstrument" v-model="form.sourceInstrument"
                                                                @blur="validateField('sourceInstrument')"
                                                                @focus="clearError('sourceInstrument')" required>
                                                            <div class="invalid-feedback"
                                                                v-if="errors.sourceInstrument">{{errors.sourceInstrument
                                                                }}</div>
                                                        </div>

                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-white"
                                                            data-bs-dismiss="modal">{{ $t('message.CancelButton')
                                                            }}</button>
                                                        <button type="button" @click="submitModal()"
                                                            :disabled="!isModalValid" class="btn btn-logo">{{
                                                            $t('message.saveAndInsert') }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Ownership percentage inputs -->
                                     
                                    <div v-for="(owner, index) in ownershipPercentages" :key="index"
                                        class="mt-2 mb-3 col-md-3 col-6">
                                        <label :for="'ownership' + index">{{ owner.name }} - نسبة التملك</label>
                                        <input type="number" class="form-control" v-model.number="owner.percentage"
                                            @input="adjustRemainingPercentages(index)" :id="'ownership' + index" min="0"
                                            max="100">
                                    </div>
                                    <div v-if="totalPercentage > 100" class="text-danger mt-2">
                                        {{ 'إجمالي النسب المئوية يتجاوز 100٪. يرجى ضبط النسب.' }}
                                    </div>

                                </div>
                            </div>
                        </div>



                    </div>
                    <div class="d-flex justify-content-between mt-3 action-content">
                        <button class="btn btn-ghost-secondary" @click="prevStep" :disabled="currentStep === 1"><i
                                v-if="isRTL" class="bi-chevron-right small"></i><i v-else
                                class="bi-chevron-left small"></i> {{$t('message.previous')}} </button>
                        <button v-if="this.currentStep < this.steps.length" class="btn btn-logo" @click="nextStep"
                            :disabled="!isStepValid">{{$t('message.next')}} <i v-if="isRTL"
                                class="bi-chevron-left small"></i><i v-else class="bi-chevron-right small"></i></button>
                        <button v-else class="btn btn-logo" @click="sendForm()"
                            :disabled="!isStepValid">{{$t('message.save')}}</button>
                    </div>
                </div>

            </div>
        </div>
        <!-- </div> -->



    </div>
</template>

<script>

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';




export default {
    name: 'RealEstateUnitManagement',
    components: {

        vSelect
    },
    data() {
        return {
            currentStep: 1,
            ownershipPercentages: [],

            steps: [
                { id: 1, title: this.$t('message.data'), content: this.$t('message.unitData'), completed: false },
                { id: 2, title: this.$t('message.ownersManagement'), content: this.$t('message.managementOptionsContent'), completed: false },
            ],
            form: {
                unitNumber: '',
                descriptionUnit: '',
                space: '',
                legalOwnership: [],
                selectedAssociationId:'',
                propertyNumberSelect:'',
                unitCode: '',
                // ownerName1: '',
                bathroomsNumber: '',
                FloorNumber: '',
                bedroomsNumber: '',
                // ownershipercentage: '',
                ownedSpace: '',
                northBoundary: '',
                southBoundary: '',
                eastBoundary: '',
                westBoundary: '',

                // for modal 
                ownerName: '',
                nationalId: '',
                blockNumber: '',
                propertyType: '',
                spaceModal: '',
                instrumentNumber: '',
                sourceInstrument: '',
                //end modal
            },

            managers: [
                'المالك 1',
                'المالك 2',
                'المالك 3',
            ],
            associations: [
                '1',
                '32',
                '35',
                '42'
            ],
            propertyTypes: [
                'شقة بثلاثة غرف',
                'شقة بخمسة غرف',
                'مجمع سكني',
                'فيلا'
            ],
            errors: {
                unitNumber: '',
                descriptionUnit: '',
                space: '',
                legalOwnership: '',
                selectedAssociationId:'',
                propertyNumberSelect:'',
                unitCode: '',
                // ownerName1: '',
                bathroomsNumber: '',
                bedroomsNumber: '',
                FloorNumber: '',
                // ownershipercentage: '',
                ownedSpace: '',
                northBoundary: '',
                southBoundary: '',
                eastBoundary: '',
                westBoundary: '',

                // for modal
                ownerName: '',
                nationalId: '',
                blockNumber: '',
                propertyType: '',
                spaceModal: '',
                instrumentNumber: '',
                sourceInstrument: '',
                //  end modal

            }
        }
    },
    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
        direction() {
            return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
        },
        isModalValid() {
            return this.form.ownerName && this.form.nationalId && this.form.propertyType &&
                this.form.blockNumber && this.form.spaceModal && this.form.instrumentNumber &&
                this.form.sourceInstrument;
        },
        totalPercentage() {
            return this.ownershipPercentages.reduce((total, owner) => total + owner.percentage, 0);
        },
        isStepValid() {
            // تحقق من صحة الخطوة الحالية
            if (this.currentStep === 1) {
                const step1Fields = [
                    'unitNumber',
                    'descriptionUnit',
                    'space',
                    'selectedAssociationId',
                    'propertyNumberSelect',
                    'unitCode',
                    // 'ownerName1',
                    'bathroomsNumber',
                    'FloorNumber',
                    'bedroomsNumber',
                    // 'ownershipercentage',
                    'ownedSpace',
                    'northBoundary',
                    'southBoundary',
                    'eastBoundary',
                    'westBoundary',
                ];
                // تحقق من عدم وجود أخطاء في الحقول الخاصة بالخطوة 1
                const step1Errors = step1Fields.some(field => this.errors[field]);

                // تحقق من ملء جميع الحقول الخاصة بالخطوة 1
                const step1Filled = step1Fields.every(field => this.form[field] !== '');

                // تحقق من أن selectedAssociationId ليس فارغًا أو null
                if (this.form.selectedAssociationId === '' || this.form.selectedAssociationId === null) {
                    return false;
                }
                // تحقق من أن propertyNumberSelect ليس فارغًا أو null
                if (this.form.propertyNumberSelect === '' || this.form.propertyNumberSelect === null) {
                    return false;
                }

                return !step1Errors && step1Filled;

                // return Object.keys(this.errors).length === 0 && Object.values(this.form).every(value => value !== '');
            }
            // تحقق صحة الخطوة 2
            if (this.currentStep === 2) {
                if (this.form.legalOwnership.length === 0) {
                    return false;
                }

                // تحقق من أن أي مالك ليس لديه نسبة ملكية 0 أو نسبة ملكية فارغة
                const hasInvalidPercentage = this.ownershipPercentages.some(owner => 
                    owner.percentage === 0 || owner.percentage === '' || owner.percentage == null
                );

                if (hasInvalidPercentage) {
                    return false;
                }

                // تحقق من أن مجموع النسب المئوية يساوي 100
                const totalPercentage = this.ownershipPercentages.reduce((total, owner) => {
                    return total + parseFloat(owner.percentage || 0);
                }, 0);

                if (totalPercentage !== 100) {
                    return false;
                }
              
            }


            return true;
        }
    },
    watch: {
        'form.legalOwnership': {
            handler() {
                this.updateOwnershipPercentages();
            },
            deep: true
        },
        ownershipPercentages: {
            handler() {
                // Ensure total does not exceed 100%
                this.ownershipPercentages.forEach((owner, index) => {
                    if (this.totalPercentage > 100) {
                        this.adjustRemainingPercentages(index);
                    }
                });
            },
            deep: true
        },
        editUnitId: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.isEditMode = true;
                    this.fetchPropertyData(newVal);
                } else {
                    this.isEditMode = false;
                    this.resetForm();
                }
            }
        }
    },
    methods: {
        goToStep(step) {
            if (step <= this.currentStep) {
                this.currentStep = step;
            }
        },
        nextStep() {
            if (this.currentStep < this.steps.length) {
                this.steps[this.currentStep - 1].completed = true;
                this.currentStep++;
            }
            else if (this.currentStep == this.steps.length) {
                console.log('done');
            }
        },
        prevStep() {
            if (this.currentStep > 1) {
                this.currentStep--;
            }
        },
        updateOwnershipPercentages() {
            const totalOwners = this.form.legalOwnership.length;

            // Ensure ownershipPercentages array matches the selected owners
            this.ownershipPercentages = this.form.legalOwnership.map((owner, index) => {
                return {
                    name: owner,
                    percentage: this.ownershipPercentages[index]?.percentage || 0
                };
            });
        },
        adjustRemainingPercentages(index) {
            if (this.totalPercentage > 100) {
                this.ownershipPercentages[index].percentage -= (this.totalPercentage - 100);
            }
        },
        validateField(field) {
            if (!this.form[field]) {
                this.$set(this.errors, field, this.$t('message.errorHandle'));
            }
        },
        clearError(field) {
            this.$delete(this.errors, field);
        },


        submitModal() {
            if (this.isModalValid) {
                this.managers.push(this.form.ownerName);
                this.form = {
                    ownerName: '',
                    nationalId: '',
                    blockNumber: '',
                    propertyType: '',
                    spaceModal: '',
                    instrumentNumber: '',
                    sourceInstrument: '',
                }
                $('#addnewusermodel').modal('hide');
            }
        },


        getEmptyForm() {

            //   this.form.contractName= '';
            //   this.form.contractId= '';
            //   this.form.unitNumber= '';
            //   this.form.establishmentDate= '';
            //   this.form.expireDate= '';
            //   this.form.firstApproval= '';
            //   this.form.unifiedNumber= '';
            //   this.form.establishmentNumber= '';
            //   this.form.ownerName = '';
            //   this.form.nationalId = '';
            //   this.form.blockNumber = '';
            //   this.form.propertyType = '';
            //   this.form.spaceModal = '';
            //   this.form.instrumentNumber = '';
            //   this.form.sourceInstrument = '';
            //   this.manager = '';
            //   this.appointmentDateFrom = '';
            //   this.appointmentDateTo = '';
            //   this.salary = '';
            //   this.hasCommissions = '';
            //   this.commissionName = '';
            //   this.commissionType = '';
            //   this.commissionValue = '';
        },
        markAllStepsUnCompleted() {
            this.steps = this.steps.map(step => ({ ...step, completed: false }));
        },
        sendForm() {
            this.currentStep = 1;
            this.markAllStepsUnCompleted();
            this.getEmptyForm();
            console.log(this.steps);
        }
    }

}
</script>


<style scoped>
.content-card {
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .075);
    margin-top: 80px;
    padding: 0px;
    background-color: #fff;
    background-clip: border-box;
    border: 0.0625rem solid rgba(231, 234, 243, 0.7);
    border-radius: 0.75rem;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 2rem
}


.stepper-wrapper {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 20px; */
    /* padding-right: 1rem; */
    padding-top: 35px;
    /* padding-bottom: 25px; */
}

.step-content {
    padding: 20px 15px 15px;
    padding-top: 35px;
}

.action-content {
    padding: 20px 15px 15px;
}

.stepper {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
}

.stepper-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
}

.stepper-item::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    height: 20px;
    width: 4px;
    background: #ddd;
    transform: translateX(-50%);
}

.stepper-item:last-child::after {
    display: none;
}

.stepper-item.active .step-counter,
.stepper-item.completed .step-counter {
    background: #0d6efd;
    color: white;
}

.step-counter {
    width: 2.40625rem;
    height: 2.40625rem;
    background: #ddd;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.step-name {
    font-size: 14px;
    text-align: center;
}

.stepper-item.active {
    color: #0d6efd;
}

.form-select {
    width: 70%;
    margin-left: 5px;
}

.form-select-rtl {
    background-position: left 0.75rem center;
}

.jadda-pattern-dark {
    background-image: url(../assets/pattren-dark.svg);
    background-size: 150px;
}

.card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
    /* color: var(--bs-card-cap-color); */
    /* background-color: var(--bs-card-cap-bg); */
    border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
}

.card-header:first-child {
    border-radius: 0.6875rem 0.6875rem 0 0;
}

.page-header-content {
    padding-right: 20px;
    padding-left: 20px;
}

.page-header-title {
    margin-bottom: .5rem;
}

.page-header-text {
    margin-bottom: .25rem;
    color: #677788;
    font-size: 14px
}

.h4,
h4 {
    font-size: .984375rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    /* margin-bottom: .5rem; */
    font-weight: 600;
    line-height: 1.2;
    color: #1E202C;
}

.h5,
h5 {
    font-size: .875rem;
}

.bg-light {
    background-color: rgba(249, 250, 252, 1) !important;
    min-height: 100%;
}

.form-label {
    padding-top: .675rem;
    padding-bottom: .675rem;
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
    color: #1e2022;
}

.form-control {
    border: 1px solid #d0d7db;
}

.form-control-sm {
    min-height: calc(1.5em + 1.125rem);
    padding: .5rem 1rem;
    font-size: .8125rem;
    border-radius: .3125rem;
}

.text-danger {
    color: #ff5252 !important;
    margin-top: 5px;
    font-size: 12px;
}

.is-invalid {
    border-color: #ff5252;
}

.invalid-feedback {
    color: #dc3545;
}

.commissions {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 10px;
    margin-left: 10px;
}

.commissions-content {
    margin-top: 12px;
}

.stepTwoContent {
    border: 1px solid #ddd;
    border-radius: .3125rem;
    padding: .6125rem 1rem;
}

.addUserBtn {
    margin-top: 42px;
    width: -webkit-fill-available;
}

.modal-header {
    padding-bottom: 25px;
    background-image: url(../assets/pattren-dark.svg);
    background-size: 150px;
    background-color: #f9fafc;
}

.btn-close {
    font-size: smaller;
}

.uploaded-files {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 15px;
    justify-content: center;
}

.file-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #dee2e6;
    padding: 7px;
    border-radius: 5px;
    background-color: #f8f9fa;
    width: 22%;
    justify-content: center;
    min-height: 165px;
}

.uploaded-image {
    width: 100px;
    /* width: -webkit-fill-available; */
    height: 90px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 5px;
}

.file-item progress {
    width: 100%;
    margin: 5px 0;
    height: 5px;
    border-radius: 50px;
}

.file-item button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    padding: initial;
}

.ps-md-0-rtl {
    padding-left: 0 !important;
}

.ps-md-0-ltr {
    padding-right: 0 !important;
}

.pe-md-0-rtl {
    padding-right: 0 !important;
}

.pe-md-0-ltr {
    padding-left: 0 !important;
}

@media(max-width:1024px) {

    .ps-md-0-ltr,
    .pe-md-0-rtl {
        padding-right: calc(1.5rem * .5) !important;
    }

    .ps-md-0-rtl,
    .pe-md-0-ltr {
        padding-left: calc(1.5rem * .5) !important;
    }
}

</style>