<template>
    <div>
      <PropertyWithAssociationSelectionForm :isEditMode="true" />
    </div>
  </template>
  
  <script>

import PropertyWithAssociationSelectionForm from '../../components/PropertyWithAssociationSelectionForm.vue';  
  
export default {
    components: {
      PropertyWithAssociationSelectionForm
    },
    data() {
      return {
        propertyData: {}
      };
    },
  };
  </script>
  