<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
        <div class="content-card">
            <div class="page-header-content">
                <div class="row mb-3">
                    <div class="col-sm mb-2 mb-sm-0">
                        <div class="d-flex gap-2 align-items-center mb-3">
                            <h1 class="page-header-title mb-0">{{ isEditMode ? $t('message.modifyPropertyData') : $t('message.createNewProperty') }}</h1>
                        </div>
                        <p class="page-header-text">{{ isEditMode ? $t('message.modifyPropertyDataParagraph') : $t('message.createNewPropertyParagraph') }}</p>
                    </div>
                </div>
                <!-- End Row -->
                    <div class="step-content">
                    <div>
                        <div class="mt-5">
                            <div class="row justify-content-start">

                                <div class="mb-3 col-md-9 col-6">
                                    <div>
                                        <label for="legalOwnership"
                                            class="form-label">{{ $t('message.legalOwnership') }}</label>
                                        <v-select :dir="direction" v-model="form.legalOwnership" :options="managers"
                                            :class="{ 'is-invalid': errors.legalOwnership }" multiple 
                                            :placeholder="$t('message.selectLegalOwnership')"
                                            id="multiple-select-custom-field"></v-select>
                                        <div class="invalid-feedback" v-if="errors.legalOwnership">{{ errors.legalOwnership
                                            }}</div>
                                    </div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <button type="button" class="btn btn-success addUserBtn btn-sm" data-bs-toggle="modal"
                                        data-bs-target="#addnewusermodel">
                                        {{ $t('message.AddNewOwner') }}
                                    </button>

                                    <div class="modal fade " id="addnewusermodel" tabindex="-1"
                                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered scrollable">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h4 class="modal-title" id="exampleModalLabel">
                                                        {{ $t('message.AddNewOwner') }}</h4>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">

                                                    <div class="mb-3 col-md-12 col-6">
                                                        <label for="ownerName"
                                                            class="form-label">{{ $t('message.ownerName') }}</label>
                                                        <input type="text" :placeholder="$t('message.ownerName')"
                                                            :class="{ 'is-invalid': errors.ownerName }"
                                                            class="form-control form-control-sm" id="ownerName"
                                                            v-model="form.ownerName" @blur="validateField('ownerName')"
                                                            @focus="clearError('ownerName')" required>
                                                        <div class="invalid-feedback" v-if="errors.ownerName">{{errors.ownerName }}</div>
                                                    </div>
                                                    <div class="mb-3 col-md-12 col-6">
                                                        <label for="nationalId"
                                                            class="form-label">{{ $t('message.idNumber') }}</label>
                                                        <input type="text" placeholder="xxxxxxxxxxxxxx"
                                                            :class="{ 'is-invalid': errors.nationalId }"
                                                            class="form-control form-control-sm" id="nationalId"
                                                            v-model="form.nationalId" @blur="validateField('nationalId')"
                                                            @focus="clearError('nationalId')" required>
                                                        <div class="invalid-feedback" v-if="errors.nationalId">{{errors.nationalId }}</div>
                                                    </div>
                                                    <div class="mb-3 col-md-12 col-6">
                                                        <label for="propertyType"
                                                            class="form-label">{{ $t('message.PropertyType') }}</label>
                                                        <select v-model="form.propertyType"
                                                            :class="{ 'is-invalid': errors.propertyType }"
                                                            class="form-control form-control-sm" id="propertyType"
                                                            @blur="validateField('propertyType')"
                                                            @focus="clearError('propertyType')" required>
                                                            <option value="" disabled selected>
                                                                {{ $t('message.selectPropertyType') }}</option>
                                                            <option v-for="property in propertyTypes" :key="property"
                                                                :value="property">{{ property }}</option>
                                                        </select>
                                                        <div class="invalid-feedback" v-if="errors.propertyType">{{errors.propertyType }}</div>
                                                    </div>
                                                    <div class="mb-3 col-md-12 col-6">
                                                        <label for="blockNumber"
                                                            class="form-label">{{ $t('message.PropertyNumber') }}</label>
                                                        <input type="number" :placeholder="$t('message.PropertyNumber')"
                                                            :class="{ 'is-invalid': errors.blockNumber }"
                                                            class="form-control form-control-sm" id="blockNumber"
                                                            v-model="form.blockNumber" @blur="validateField('blockNumber')"
                                                            @focus="clearError('blockNumber')" required>
                                                        <div class="invalid-feedback" v-if="errors.blockNumber">{{errors.blockNumber }}</div>
                                                    </div>
                                                    <div class="mb-3 col-md-12 col-6">
                                                        <label for="spaceModal"
                                                            class="form-label">{{ $t('message.area') }}</label>
                                                        <input type="text" :placeholder="$t('message.area')"
                                                            :class="{ 'is-invalid': errors.spaceModal }"
                                                            class="form-control form-control-sm" id="spaceModal"
                                                            v-model="form.spaceModal" @blur="validateField('spaceModal')"
                                                            @focus="clearError('spaceModal')" required>
                                                        <div class="invalid-feedback" v-if="errors.spaceModal">{{errors.spaceModal }}</div>
                                                    </div>
                                                    <div class="mb-3 col-md-12 col-6">
                                                        <label for="instrumentNumber"
                                                            class="form-label">{{ $t('message.deedNumber') }}</label>
                                                        <input type="number" :placeholder="$t('message.deedNumber')"
                                                            :class="{ 'is-invalid': errors.instrumentNumber }"
                                                            class="form-control form-control-sm" id="instrumentNumber"
                                                            v-model="form.instrumentNumber"
                                                            @blur="validateField('instrumentNumber')"
                                                            @focus="clearError('instrumentNumber')" required>
                                                        <div class="invalid-feedback" v-if="errors.instrumentNumber">{{errors.instrumentNumber }}</div>
                                                    </div>
                                                    <div class="mb-3 col-md-12 col-6">
                                                        <label for="sourceInstrument"
                                                            class="form-label">{{ $t('message.deedSource') }}</label>
                                                        <input type="number" :placeholder="$t('message.deedSource')"
                                                            :class="{ 'is-invalid': errors.sourceInstrument }"
                                                            class="form-control form-control-sm" id="sourceInstrument"
                                                            v-model="form.sourceInstrument"
                                                            @blur="validateField('sourceInstrument')"
                                                            @focus="clearError('sourceInstrument')" required>
                                                        <div class="invalid-feedback" v-if="errors.sourceInstrument">{{errors.sourceInstrument }}</div>
                                                    </div>

                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-white"
                                                        data-bs-dismiss="modal">{{ $t('message.CancelButton') }}</button>
                                                    <button type="button" @click="submitModal()" :disabled="!isModalValid"
                                                        class="btn btn-logo">{{ $t('message.saveAndInsert') }}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="street" class="form-label">{{ $t('message.street') }}</label>
                                    <input type="text" :placeholder="$t('message.street')"
                                        :class="{ 'is-invalid': errors.street }" class="form-control form-control-sm"
                                        id="street" v-model="form.street" @blur="validateField('street')"
                                        @focus="clearError('street')" required>
                                    <div class="invalid-feedback" v-if="errors.street">{{ errors.street }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="space" class="form-label">{{ $t('message.area') }}</label>
                                    <input type="text" :placeholder="$t('message.area')"
                                        :class="{ 'is-invalid': errors.space }" class="form-control form-control-sm"
                                        id="space" v-model="form.space" @blur="validateField('space')"
                                        @focus="clearError('space')" required>
                                    <div class="invalid-feedback" v-if="errors.space">{{ errors.space }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="flatArea" class="form-label">{{ $t('message.surfaceArea') }}</label>
                                    <input type="text" :placeholder="$t('message.surfaceArea')"
                                        :class="{ 'is-invalid': errors.flatArea }" class="form-control form-control-sm"
                                        id="flatArea" v-model="form.flatArea" @blur="validateField('flatArea')"
                                        @focus="clearError('flatArea')" required>
                                    <div class="invalid-feedback" v-if="errors.flatArea">{{ errors.flatArea }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="plotNumber" class="form-label">{{ $t('message.plotNumber') }}</label>
                                    <input type="number" :placeholder="$t('message.plotNumber')"
                                        :class="{ 'is-invalid': errors.plotNumber }" class="form-control form-control-sm"
                                        id="plotNumber" v-model="form.plotNumber" @blur="validateField('plotNumber')"
                                        @focus="clearError('plotNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.plotNumber">{{ errors.plotNumber }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="bankNumber" class="form-label">{{ $t('message.bankAccountNumber') }}</label>
                                    <input type="number" :placeholder="$t('message.bankAccountNumber')"
                                        :class="{ 'is-invalid': errors.bankNumber }" class="form-control form-control-sm"
                                        id="bankNumber" v-model="form.bankNumber" @blur="validateField('bankNumber')"
                                        @focus="clearError('bankNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.bankNumber">{{ errors.bankNumber }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="instrumentNumber1" class="form-label">{{ $t('message.deedNumber') }}</label>
                                    <input type="number" :placeholder="$t('message.deedNumber')"
                                        :class="{ 'is-invalid': errors.instrumentNumber1 }"
                                        class="form-control form-control-sm" id="instrumentNumber1"
                                        v-model="form.instrumentNumber1" @blur="validateField('instrumentNumber1')"
                                        @focus="clearError('instrumentNumber1')" required>
                                    <div class="invalid-feedback" v-if="errors.instrumentNumber1">{{errors.instrumentNumber1 }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="sourceInstrument1" class="form-label">{{ $t('message.deedSource') }}</label>
                                    <input type="number" :placeholder="$t('message.deedSource')"
                                        :class="{ 'is-invalid': errors.sourceInstrument1 }"
                                        class="form-control form-control-sm" id="sourceInstrument1"
                                        v-model="form.sourceInstrument1" @blur="validateField('sourceInstrument1')"
                                        @focus="clearError('sourceInstrument1')" required>
                                    <div class="invalid-feedback" v-if="errors.sourceInstrument1">{{errors.sourceInstrument1 }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="floorCount" class="form-label">{{ $t('message.numberOfFloors') }}</label>
                                    <input type="number" :placeholder="$t('message.numberOfFloors')"
                                        :class="{ 'is-invalid': errors.floorCount }" class="form-control form-control-sm"
                                        id="floorCount" v-model="form.floorCount" @blur="validateField('floorCount')"
                                        @focus="clearError('floorCount')" required>
                                    <div class="invalid-feedback" v-if="errors.floorCount">{{ errors.floorCount }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="elevatorCount"
                                        class="form-label">{{ $t('message.numberOfElevators') }}</label>
                                    <input type="number" :placeholder="$t('message.numberOfElevators')"
                                        :class="{ 'is-invalid': errors.elevatorCount }" class="form-control form-control-sm"
                                        id="elevatorCount" v-model="form.elevatorCount"
                                        @blur="validateField('elevatorCount')" @focus="clearError('elevatorCount')"
                                        required>
                                    <div class="invalid-feedback" v-if="errors.elevatorCount">{{ errors.elevatorCount }}
                                    </div>
                                </div>


                                <div class="mb-3 col-md-3 col-6">
                                    <label for="constructionType"
                                        class="form-label">{{ $t('message.typeOfConstruction') }}</label>
                                    <input type="number" :placeholder="$t('message.typeOfConstruction')"
                                        :class="{ 'is-invalid': errors.constructionType }"
                                        class="form-control form-control-sm" id="constructionType"
                                        v-model="form.constructionType" @blur="validateField('constructionType')"
                                        @focus="clearError('constructionType')" required>
                                    <div class="invalid-feedback" v-if="errors.constructionType">{{ errors.constructionType
                                        }}</div>
                                </div>
                                <div class="mb-3 col-md-3 col-6">
                                    <label for="constructionYear"
                                        class="form-label">{{ $t('message.yearOfConstruction') }}</label>
                                    <input type="number" :placeholder="$t('message.yearOfConstruction')"
                                        :class="{ 'is-invalid': errors.constructionYear }"
                                        class="form-control form-control-sm" id="constructionYear"
                                        v-model="form.constructionYear" @blur="validateField('constructionYear')"
                                        @focus="clearError('constructionYear')" required>
                                    <div class="invalid-feedback" v-if="errors.constructionYear">{{ errors.constructionYear
                                        }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="northBoundary" class="form-label">{{ $t('message.northernBoundary') }}</label>
                                    <input type="text" :placeholder="$t('message.northernBoundary')"
                                        :class="{ 'is-invalid': errors.northBoundary }" class="form-control form-control-sm"
                                        id="northBoundary" v-model="form.northBoundary"
                                        @blur="validateField('northBoundary')" @focus="clearError('northBoundary')"
                                        required>
                                    <div class="invalid-feedback" v-if="errors.northBoundary">{{ errors.northBoundary }}
                                    </div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="southBoundary" class="form-label">{{ $t('message.southernBoundary') }}</label>
                                    <input type="text" :placeholder="$t('message.southernBoundary')"
                                        :class="{ 'is-invalid': errors.southBoundary }" class="form-control form-control-sm"
                                        id="southBoundary" v-model="form.southBoundary"
                                        @blur="validateField('southBoundary')" @focus="clearError('southBoundary')"
                                        required>
                                    <div class="invalid-feedback" v-if="errors.southBoundary">{{ errors.southBoundary }}
                                    </div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="eastBoundary" class="form-label">{{ $t('message.eastBoundary') }}</label>
                                    <input type="text" :placeholder="$t('message.eastBoundary')"
                                        :class="{ 'is-invalid': errors.eastBoundary }" class="form-control form-control-sm"
                                        id="eastBoundary" v-model="form.eastBoundary" @blur="validateField('eastBoundary')"
                                        @focus="clearError('eastBoundary')" required>
                                    <div class="invalid-feedback" v-if="errors.eastBoundary">{{ errors.eastBoundary }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="westBoundary" class="form-label">{{ $t('message.westBoundary') }}</label>
                                    <input type="text" :placeholder="$t('message.westBoundary')"
                                        :class="{ 'is-invalid': errors.westBoundary }" class="form-control form-control-sm"
                                        id="westBoundary" v-model="form.westBoundary" @blur="validateField('westBoundary')"
                                        @focus="clearError('westBoundary')" required>
                                    <div class="invalid-feedback" v-if="errors.westBoundary">{{ errors.westBoundary }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="electricityAccountNumber"
                                        class="form-label">{{ $t('message.electricityAccountNumber') }}</label>
                                    <input type="number" :placeholder="$t('message.electricityAccountNumber')"
                                        :class="{ 'is-invalid': errors.electricityAccountNumber }"
                                        class="form-control form-control-sm" id="electricityAccountNumber"
                                        v-model="form.electricityAccountNumber"
                                        @blur="validateField('electricityAccountNumber')"
                                        @focus="clearError('electricityAccountNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.electricityAccountNumber">{{errors.electricityAccountNumber }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="electricityNumber "
                                        class="form-label">{{ $t('message.electricityNumber') }}</label>
                                    <input type="number" :placeholder="$t('message.electricityNumber')"
                                        :class="{ 'is-invalid': errors.electricityNumber }"
                                        class="form-control form-control-sm" id="electricityNumber"
                                        v-model="form.electricityNumber" @blur="validateField('electricityNumber')"
                                        @focus="clearError('electricityNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.electricityNumber">{{errors.electricityNumber }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="subscriptionElectricityNumber"
                                        class="form-label">{{ $t('message.subscriptionElectricityNumber') }}</label>
                                    <input type="number" :placeholder="$t('message.subscriptionElectricityNumber')"
                                        :class="{ 'is-invalid': errors.subscriptionElectricityNumber }"
                                        class="form-control form-control-sm" id="subscriptionElectricityNumber"
                                        v-model="form.subscriptionElectricityNumber"
                                        @blur="validateField('subscriptionElectricityNumber')"
                                        @focus="clearError('subscriptionElectricityNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.subscriptionElectricityNumber">{{errors.subscriptionElectricityNumber }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="waterAccountNumber"
                                        class="form-label">{{ $t('message.waterAccountNumber') }}</label>
                                    <input type="number" :placeholder="$t('message.waterAccountNumber')"
                                        :class="{ 'is-invalid': errors.waterAccountNumber }"
                                        class="form-control form-control-sm" id="waterAccountNumber"
                                        v-model="form.waterAccountNumber" @blur="validateField('waterAccountNumber')"
                                        @focus="clearError('waterAccountNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.waterAccountNumber">{{
                                        errors.waterAccountNumber }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="WaterNumber " class="form-label">{{$t('message.WaterNumber')}}</label>
                                    <input type="number" :placeholder="$t('message.WaterNumber')"
                                        :class="{'is-invalid': errors.WaterNumber}" class="form-control form-control-sm"
                                        id="WaterNumber" v-model="form.WaterNumber" @blur="validateField('WaterNumber')"
                                        @focus="clearError('WaterNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.WaterNumber">{{ errors.WaterNumber }}</div>
                                </div>

                                <h5 class="mt-3">{{ $t('message.propertyLocation') }}</h5>
                                <p>{{ $t('message.selectFromMap') }}</p>

                                <l-map :zoom="zoom" :center="center" style="height: 500px; width: 100%;"
                                    @update:center="updateLatLngFromMap">
                                    <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
                                    <l-marker :lat-lng="markerPosition" :draggable="true"
                                        @update:lat-lng="updateLatLngFromMarker"></l-marker>
                                </l-map>
                                <div class="mt-3 col-md-6 col-6">
                                    <label for="latitude" class="form-label">{{$t('message.latitude')}}</label>
                                    <input id="latitude" class="form-control form-control-sm" type="text" v-model="lat"
                                        @change="updateMarkerPosition" />
                                </div>
                                <div class="mt-3 col-md-6 col-6">
                                    <label for="longitude" class="form-label">{{$t('message.longitude')}}</label>
                                    <input id="longitude" class="form-control form-control-sm" type="text" v-model="lng"
                                        @change="updateMarkerPosition" />
                                </div>




                            </div>
                        </div>

                    </div>
                    
                    </div>

                <div class="mt-5" style="text-align: end">
                    <button class="btn btn-logo" @click="handleSubmit" :disabled="!isStepValid">{{ isEditMode ? $t('message.save') : $t('message.saveAndInsert') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';


// تخصيص الرمز الافتراضي
delete L.Icon.Default.prototype._getIconUrl;


L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


export default {
    props: {
        associationId: {
        type: Number,
        required: false
        },
        propertyId: {
        type: Number,
        required: false
        }
    },
    components: {
        LMap,
        LTileLayer,
        LMarker,
        vSelect
    },
    data() {
        return {
            center: [24.7136, 46.6753], // موقع الرياض
            markerPosition: [24.7136, 46.6753],
            lat: 24.7136,
            lng: 46.6753,
            zoom: 10,
            form:{
                street: '',
                space: '',
                flatArea: '',
                legalOwnership: [],
                plotNumber: '',
                bankNumber: '',
                elevatorCount: '',
                floorCount: '',
                constructionYear: '',
                constructionType: '',
                instrumentNumber1: '',
                sourceInstrument1: '',
                northBoundary: '',
                southBoundary: '',
                eastBoundary: '',
                westBoundary: '',
                electricityAccountNumber: '',
                electricityNumber: '',
                subscriptionElectricityNumber: '',
                waterAccountNumber: '',
                WaterNumber: '',
                // for modal 
                ownerName: '',
                nationalId: '',
                blockNumber: '',
                propertyType: '',
                spaceModal: '',
                instrumentNumber: '',
                sourceInstrument: '',
                //end modal
            },
            managers: [
                'مدير 1',
                'مدير 2',
                'مدير 3'
            ],
            propertyTypes: [
                'شقة بثلاثة غرف',
                'شقة بخمسة غرف',
                'مجمع سكني',
                'فيلا'
            ],
            errors: {
            street: '',
            space: '',
            flatArea: '',
            legalOwnership: '',
            plotNumber: '',
            bankNumber: '',
            elevatorCount: '',
            floorCount: '',
            constructionYear: '',
            instrumentNumber1: '',
            sourceInstrument1: '',
            northBoundary: '',
            southBoundary: '',
            eastBoundary: '',
            westBoundary: '',
            electricityAccountNumber: '',
            electricityNumber: '',
            subscriptionElectricityNumber: '',
            waterAccountNumber: '',
            WaterNumber: '',
            // for modal
            ownerName: '',
            nationalId: '',
            blockNumber: '',
            propertyType: '',
            spaceModal: '',
            instrumentNumber:'',
            sourceInstrument: '',
            //  end modal
            
        }

        }
    },
    mounted() {
        if (this.propertyId) {
            console.log('send request to get data');
        }
    },
    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
        direction() {
            return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
        },
        isModalValid() {
            return this.form.ownerName && this.form.nationalId && this.form.propertyType &&
                this.form.blockNumber && this.form.spaceModal && this.form.instrumentNumber &&
                this.form.sourceInstrument;
        },
        isStepValid() {
                const step3Fields = [
                'street',
                'space',
                'flatArea',
                'legalOwnership',
                'plotNumber',
                'bankNumber',
                'elevatorCount',
                'floorCount',
                'constructionYear',
                'constructionType',
                'instrumentNumber1',
                'sourceInstrument1',
                'northBoundary',
                'southBoundary',
                'eastBoundary',
                'westBoundary',
                'electricityAccountNumber',
                'electricityNumber',
                'subscriptionElectricityNumber',
                'waterAccountNumber',
                'WaterNumber',
                ];
                const step3Errors = step3Fields.some(field => this.errors[field]);
                
                const step3Filled = step3Fields.every(field => this.form[field] !== '');
                if (this.form.legalOwnership.length == 0) {
                return false;
                }
                
                return !step3Errors && step3Filled;
        }
    },
    watch: {
        propertyId: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                this.isEditMode = true;
                this.fetchPropertyData(newVal);
                } else {
                this.isEditMode = false;
                this.resetForm();
                }
            }
        }
    },
    methods: {
        fetchPropertyData(id) {
            // Fetch property data and populate form
        },
        resetForm() {
            // Reset form data
        
        },
        validateField(field) {
            if (!this.form[field]) {
                this.$set(this.errors, field, this.$t('message.errorHandle'));
            }
        },
        clearError(field) {
            this.$delete(this.errors, field);
        },
        submitModal(){
            if (this.isModalValid) {
                this.managers.push(this.form.ownerName);
                this.form = {
                ownerName: '',
                nationalId: '',
                blockNumber: '',
                propertyType: '',
                spaceModal: '',
                instrumentNumber: '',
                sourceInstrument: '',
                }
                $('#addnewusermodel').modal('hide');
            }
        },
        updateLatLngFromMarker(latLng) {
            this.lat = latLng.lat;
            this.lng = latLng.lng;
            this.markerPosition = [this.lat, this.lng];
        },
        updateLatLngFromMap(center) {
            this.center = center;
        },
        updateMarkerPosition() {
            this.markerPosition = [parseFloat(this.lat), parseFloat(this.lng)];
            this.center = [...this.markerPosition]; // لتحديث المركز عند تحديث marker
        },
        // sendForm(){
        //     this.$nextTick(() => {
        //     this.$router.push('/' + this.$i18n.locale + '/AssociationRealEstate/' + this.associationId);
        //     });
        // }
        handleSubmit() {
            if (this.isEditMode) {
                this.updateProperty();
            } else {
                this.createProperty();
            }
        },
        createProperty() {
            console.log('add new property');
        },
        updateProperty() {
            console.log('edit property');
        }
    },
}
</script>
<style scoped>
.content-card {
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .0);
    margin-top: 80px;
    padding: 15px;
    background-color: #fff;
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: 0.75rem;
    margin-right: 15px;
    margin-left: 15px;
}

.page-header-content{
  padding-right: 20px;
  padding-left: 20px;
}

.page-header-title {
    margin-bottom: .5rem;
}

.page-header-text {
    margin-bottom: .25rem;
    color: #677788;
    font-size: 14px
}
.addUserBtn{
  margin-top: 28px;
  width: -webkit-fill-available;
}

#main-content{
  /* max-height: 1200px; */
  min-height: 1100px;
}
.content {
  padding-top: 20px;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
}

.content-rtl {
  margin-right: 17.2rem;
}

.content-ltr {
  margin-left: 17.2rem;
}

.full-content-rtl {
  margin-right: 90px; /* Adjusted for closed sidebar */
}

.full-content-ltr {
  margin-left: 85px; /* Adjusted for closed sidebar */
}
</style>