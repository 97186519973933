<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
        <div class="content-card">
            <div class="page-header">
                <div class="row mb-3">
                    <div class="col-sm mb-2 mb-sm-0">
                        <div class="d-flex gap-2 align-items-center mb-3">
                            <h1 class="page-header-title mb-0">{{$t('message.realEstateManagement')}}</h1>
                        </div>
                        <p class="page-header-text">{{$t('message.realEstateManagementParagraph')}}</p>
                    </div>
                </div>
                <!-- End Row -->
            </div>

            <div class="row justify-content-end mb-3" v-if="selectedCount > 0">
                <div class="col-lg">
                    <!-- Datatable Info -->
                    <div id="datatableCounterInfo">
                        <div class="d-sm-flex justify-content-lg-end align-items-sm-center">
                            <span class="d-block d-sm-inline-block fs-5 me-3 mb-2 mb-sm-0">
                                {{$t('message.selectedLists')}} {{ ': ' }}<span id="datatableCounter">{{ selectedCount }}</span>
                            </span>
                            <a class="btn btn-outline-danger btn-sm mb-2 mb-sm-0 me-2" @click="deleteSelected">
                                <i class="bi-trash"></i> {{$t('message.delete')}}
                            </a>
                        </div>
                    </div>
                    <!-- End Datatable Info -->
                </div>
            </div>

            <div class="card">
                <!-- Header -->
                <div class="card-header card-header-content-md-between bg-light jadda-pattern-dark">
                    <div class="mb-md-0 col-md-4">
                        <div class="input-group input-group-merge input-group-flush">
                            <div class="input-group-prepend input-group-text">
                                <lord-icon colors="primary:#121331,secondary:#edd100" target=".input-group"
                                    src="https://cdn.lordicon.com/iszohckt.json" trigger="hover"
                                    style="width:15px;height:15px"> </lord-icon>
                            </div>
                            <b-form-input v-model="filter" :placeholder="$t('message.SearchInRealEstateList')"
                                class="mt-3 mb-3 form-control bg-white filter-search-input"></b-form-input>
                            <!-- <input id="datatableSearch" type="search" class="form-control bg-white" placeholder="البحث في قائمة الجمعيات" aria-label="Search users"> -->
                        </div>
                    </div>
                    <!-- End Search -->

                    <div class="d-grid d-sm-flex gap-2">
                        <div class="dropdown mb-2 mb-md-0">
                            <button @click="createNewProperty()" class="btn btn-logo add-new-property">{{$t('message.createNewProperty')}}</button>
                        </div>
                        <div class="dropdown">
                            <button type="button" class="btn btn-white show-lists w-100" id="showHideDropdown"
                                data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                <i class="bi-table me-1"></i> {{ $t('message.lists') }} <span
                                    class="badge bg-soft-dark text-dark rounded-circle ms-1">{{ this.fields.length }}</span>
                            </button>

                            <div class="dropdown-menu dropdown-menu-end dropdown-card"
                                aria-labelledby="showHideDropdown" style="width: 15rem;">
                                <div class="card card-sm">
                                    <div class="card-body">
                                    <div class="d-grid gap-3 w-100">
                                        <b-form-checkbox v-for="field in fields" :disabled="field.key === 'select'"
                                                        :key="field.key" v-model="field.visible" @change="updateVisibleFields">
                                        <span style="margin: 5px">{{ field.label }}</span>
                                        </b-form-checkbox>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Dropdown -->
                    </div>
                </div>
                
            <b-table hover responsive :items="filteredItems" :fields="visibleFields" :current-page="currentPage" :per-page="perPage"
                    :filter="filter" @filtered="onFiltered" @sort-changed="onSortChanged">

                    <template #head(select)="data">
                        <b-form-checkbox v-model="selectAll" @change="toggleSelectAll"></b-form-checkbox>
                    </template>

                    <template #cell(select)="row">
                        <b-form-checkbox v-model="row.item.selected"></b-form-checkbox>
                    </template>

                    <template #cell(actions)="row">
                        <!-- <b-dropdown size="sm" text="Actions" variant="link">
                        <b-dropdown-item @click="enableEdit(row.item)">Edit</b-dropdown-item>
                        <b-dropdown-item @click="deleteItem(row.item)">Delete</b-dropdown-item>
                    </b-dropdown> -->
                        <div class="btn-group" role="group">
                            <a @click="handleViewClick(row.item)" class="btn btn-white view-btn btn-sm">
                                <i class="bi-eye me-1"></i> {{ $t('message.view') }}
                            </a>

                            <!-- Button Group -->
                            <div class="btn-group">
                                <button type="button"
                                    class="btn btn-white btn-icon btn-sm dropdown-toggle dropdown-toggle-empty"
                                    id="productsEditDropdown1" data-bs-toggle="dropdown" aria-expanded="false"></button>

                                <div class="dropdown-menu dropdown-menu-end mt-1"
                                    aria-labelledby="productsEditDropdown1">
                                    <a class="dropdown-item" @click="handleEditClick(row.item)">
                                        <i class="bi-pencil-fill dropdown-item-icon"></i> {{ $t('message.modifyPropertyData') }}
                                    </a>
                                    <a class="dropdown-item" @click="enableDelete(row.item)"  data-bs-toggle="modal"
                                        data-bs-target="#delete-modal">
                                        <i class="bi-trash dropdown-item-icon"></i> {{ $t('message.delete') }}
                                    </a>
                                </div>
                            </div>
                            <!-- End Button Group -->
                        </div>
                    </template>

                    <!-- <template #cell(name)="row">
                        <template v-if="row.item.isEditing">
                            <b-form-input v-model="row.item.editName" @keyup.enter="saveEdit(row.item)"></b-form-input>
                        </template>
                        <template v-else>
                            {{ row.item.name }}
                        </template>
                    </template> -->

                    <!-- <template #cell(date)="row">
                        <template v-if="row.item.isEditing">
                            <b-form-input v-model="row.item.editDate" @keyup.enter="saveEdit(row.item)"></b-form-input>
                        </template>
                        <template v-else>
                            {{ row.item.date }}
                        </template>
                    </template> -->

                </b-table>

                <!-- delete Modal -->
                <div class="modal fade" id="delete-modal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="exampleModalLabel">{{ $t('message.deleteData') }}</h4>
                                <button type="button" class="btn-close" :class="[{ 'btn-close-rtl': isRTL, 'btn-close-ltr': !isRTL }]" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p class="page-header-text"> {{ $t('message.deleteDataParagraph') }}</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-white" data-bs-dismiss="modal">{{$t('message.CancelButton')}}</button>
                                <button type="button"  @click="deleteItemConfirmed" class="btn btn-danger">{{$t('message.delete')}}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <template v-if="filteredItems.length === 0 && filter.length > 0">
                    <div class="text-center p-4">
                        <lord-icon src="https://cdn.lordicon.com/wkppeqfv.json"
                            colors="primary:#121331,secondary:#edd100" trigger="loop" style="width:100px;height:100px">
                        </lord-icon>
                        <p class="mb-0"> {{ $t('message.noMatchingResults') }} "{{ filter }}".</p>
                    </div>
                </template>


                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
            </div>
            <!-- end card -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            items: [
                { id: 1, association: 2, area: 175, surfaceArea: 200,instrumentNumber1: 123,sourceInstrument1:12,floorCount: 10 , selected: false, isEditing: false, editStreet: '', editArea: '', editSurfaceArea: '' },
                { id: 2, association: 18, area: 175, surfaceArea: 200,instrumentNumber1: 123,sourceInstrument1:12,floorCount: 10 , selected: false, isEditing: false, editStreet: '', editArea: '', editSurfaceArea: '' },
                { id: 3, association: 32, area: 175, surfaceArea: 200,instrumentNumber1: 123,sourceInstrument1:12,floorCount: 10 , selected: false, isEditing: false, editStreet: '', editArea: '', editSurfaceArea: '' },
                { id: 4, association: 24, area: 175, surfaceArea: 200,instrumentNumber1: 123,sourceInstrument1:12,floorCount: 10 , selected: false, isEditing: false, editStreet: '', editArea: '', editSurfaceArea: '' },
                { id: 5, association: 13, area: 175, surfaceArea: 200,instrumentNumber1: 123,sourceInstrument1:12,floorCount: 10 , selected: false, isEditing: false, editStreet: '', editArea: '', editSurfaceArea: '' },
                { id: 6, association: 12, area: 175, surfaceArea: 200,instrumentNumber1: 123,sourceInstrument1:12,floorCount: 10 , selected: false, isEditing: false, editStreet: '', editArea: '', editSurfaceArea: '' },
                { id: 7, association: 9, area: 175, surfaceArea: 200,instrumentNumber1: 123,sourceInstrument1:12,floorCount: 10 , selected: false, isEditing: false, editStreet: '', editArea: '', editSurfaceArea: '' },
                { id: 8, association: 10, area: 175, surfaceArea: 200,instrumentNumber1: 123,sourceInstrument1:12,floorCount: 10 , selected: false, isEditing: false, editStreet: '', editArea: '', editSurfaceArea: '' },
                { id: 9, association: 8, area: 175, surfaceArea: 200,instrumentNumber1: 123,sourceInstrument1:12,floorCount: 10 , selected: false, isEditing: false, editStreet: '', editArea: '', editSurfaceArea: '' },
                { id: 10, association:44,area: 175, surfaceArea: 200,instrumentNumber1: 123,sourceInstrument1:12,floorCount: 10 , selected: false, isEditing: false, editStreet: '', editArea: '', editSurfaceArea: '' },
                { id: 11, association: 23,area: 175, surfaceArea: 200,instrumentNumber1: 123,sourceInstrument1:12,floorCount: 10 , selected: false, isEditing: false, editStreet: '', editArea: '', editSurfaceArea: '' },
                { id: 12, association: 6,area: 175, surfaceArea: 200,instrumentNumber1: 123,sourceInstrument1:12,floorCount: 10 , selected: false, isEditing: false, editStreet: '', editArea: '', editSurfaceArea: '' },

                // Add more items here
            ],
            fields: [
                { key: 'select', label: '', visible: true },
                { key: 'id', label: this.$t('message.serialNumber'), visible: true },
                { key: 'association', label: this.$t('message.association'), visible: true },
                // { key: 'street', label: this.$t('message.street'), visible: true },
                { key: 'area', label: this.$t('message.area'), visible: true },
                { key: 'surfaceArea', label: this.$t('message.surfaceArea'), visible: true },
                // { key: 'plotNumber', label: this.$t('message.plotNumber'), visible: true },
                // { key: 'bankNumber', label: this.$t('message.bankAccountNumber'), visible: true },
                { key: 'instrumentNumber1', label: this.$t('message.deedNumber'), visible: true },
                { key: 'sourceInstrument1', label: this.$t('message.deedSource'), visible: true },
                { key: 'floorCount', label: this.$t('message.numberOfFloors'), visible: true },
                // { key: 'elevatorCount', label: this.$t('message.numberOfElevators'), visible: true },
                // { key: 'constructionType', label: this.$t('message.typeOfConstruction'), visible: true },
                // { key: 'constructionYear', label: this.$t('message.yearOfConstruction'), visible: true },
                // { key: 'northBoundary', label: this.$t('message.northernBoundary'), visible: true },
                // { key: 'southernBoundary', label: this.$t('message.southernBoundary'), visible: true },
                // { key: 'eastBoundary', label: this.$t('message.eastBoundary'), visible: false },
                // { key: 'westBoundary', label: this.$t('message.westBoundary'), visible: false },
                // { key: 'electricityAccountNumber', label: this.$t('message.electricityAccountNumber'), visible: false },
                // { key: 'electricityNumber', label: this.$t('message.electricityNumber'), visible: false },
                // { key: 'subscriptionElectricityNumber', label: this.$t('message.subscriptionElectricityNumber'), visible: false },
                // { key: 'waterAccountNumber', label: this.$t('message.waterAccountNumber'), visible: false },
                // { key: 'WaterNumber', label: this.$t('message.WaterNumber'), visible: false },
                { key: 'actions', label: this.$t('message.actions'), visible: true }
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            filter: '',
            selectAll: false,
        };
    },
    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
        visibleFields() {
            return this.fields.filter(field => field.visible);
        },
        filteredItems() {
            return this.items.filter(item => {
                return this.visibleFields.some(field => {
                    return item[field.key] && item[field.key].toString().toLowerCase().includes(this.filter.toLowerCase());
                });
            });
        },
        selectedCount() {
            return this.items.filter(item => item.selected).length;
        },
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        updateVisibleFields() {
            this.visibleFields = this.fields.filter(field => field.visible);
        },
        toggleSelectAll() {
            const startIndex = (this.currentPage - 1) * this.perPage;
            const endIndex = startIndex + this.perPage;

            this.items.slice(startIndex, endIndex).forEach(item => (item.selected = this.selectAll));
        },
        enableDelete(item) {
            this.deleteItem = item; // تخزين العنصر الذي سيتم حذفه
        },
        deleteItemConfirmed() {
            this.items = this.items.filter(i => i.id !== this.deleteItem.id); // حذف العنصر
            this.deleteItem = {}; // إعادة تعيين العنصر المحذوف
            $('#delete-modal').modal('hide'); // إخفاء المودال
        },
        deleteSelected() {
            this.items = this.items.filter(item => !item.selected);
            this.selectAll = false;
        },
        onSortChanged(context) {
            console.log('Sort Changed', context);
        },
        createNewProperty() {
            this.$nextTick(() => {
                this.$router.push('/' + this.$i18n.locale + '/RealEstateManagement/createProperty');
            });
        },
        handleEditClick(item) {
            this.$nextTick(() => {
            this.$router.push('/' + this.$i18n.locale + '/RealEstateManagement/ModifyProperty/' + item.id);
            });
        },
        // handleViewClick(item){
        //     this.$nextTick(() => {
        //     this.$router.push('/' + this.$i18n.locale + '/ViewPropertyData/' + item.id);
        //     });
        // }
    },
    mounted() {
        this.totalRows = this.items.length;
    }
};
</script>

<style scoped>

.bg-light {
    background-color: rgba(249, 250, 252, 1) !important;
    min-height: 100%;
}

.jadda-pattern-dark {
    /* background-image: url(../assets/pattren-dark.svg); */
    background-image: url(../../assets/pattren-dark.svg);
    background-size: 150px;
}

.card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
    /* color: var(--bs-card-cap-color); */
    /* background-color: var(--bs-card-cap-bg); */
    border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
}

.card-header:first-child {
    border-radius: 0.6875rem 0.6875rem 0 0;
}

.page-header-content {
    padding-right: 20px;
    padding-left: 20px;
}

.page-header-title {
    margin-bottom: .5rem;
}

.page-header-text {
    margin-bottom: .25rem;
    color: #677788;
    font-size: 14px
}
/* .dropdown-menu.show {
    overflow: scroll !important;
    height: 300px !important;
} */
</style>