<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
        <div class="content-card">
            <div class="page-header-content">
                <div class="row mb-3">
                    <div class="col-sm mb-2 mb-sm-0">
                        <div class="d-flex gap-2 align-items-center mb-3">
                            <h1 class="page-header-title mb-0">{{ isEditMode ? $t('message.modifyUnitData') :
        $t('message.createNewRealStateUnit') }}</h1>
                        </div>
                        <p class="page-header-text">{{ isEditMode ? $t('message.modifyUnitDataParagraph') :
        $t('message.createNewRealStateUnitParagraph') }}</p>
                    </div>
                </div>
                <!-- End Row -->
                <div class="step-content">
                    <div>
                        <div class="mt-5">
                            <div class="mt-3 stepTwoContent">
                                <div class="row justify-content-start">


                                    <div class="mb-3 col-md-9 col-6">
                                        <div>
                                            <label for="legalOwnership" class="form-label">{{ 'اسم المالك' }}</label>
                                            <v-select :dir="direction" v-model="form.legalOwnership" :options="managers"
                                                :class="{ 'is-invalid': errors.legalOwnership }" multiple
                                                :placeholder="$t('message.selectLegalOwnership')"
                                                id="multiple-select-custom-field" :disabled="!enableInput"></v-select>
                                            <div class="invalid-feedback" v-if="errors.legalOwnership">{{
        errors.legalOwnership
    }}</div>
                                        </div>
                                    </div>

                                    <div class="mb-3 col-md-3 col-6">
                                        <button type="button" class="btn btn-success addUserBtn btn-sm" :disabled="!enableInput"
                                            data-bs-toggle="modal" data-bs-target="#addnewusermodel">
                                            {{ $t('message.AddNewOwner') }}
                                        </button>

                                        <div class="modal fade " id="addnewusermodel" tabindex="-1"
                                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered scrollable">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h4 class="modal-title" id="exampleModalLabel">
                                                            {{ $t('message.AddNewOwner') }}</h4>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">

                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="ownerName" class="form-label">{{
        $t('message.ownerName') }}</label>
                                                            <input type="text" :placeholder="$t('message.ownerName')"
                                                                :class="{ 'is-invalid': errors.ownerName }"
                                                                class="form-control form-control-sm" id="ownerName"
                                                                v-model="form.ownerName"
                                                                @blur="validateField('ownerName')"
                                                                @focus="clearError('ownerName')" required>
                                                            <div class="invalid-feedback" v-if="errors.ownerName">
                                                                {{ errors.ownerName }}</div>
                                                        </div>
                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="nationalId" class="form-label">{{
        $t('message.idNumber') }}</label>
                                                            <input type="text" placeholder="xxxxxxxxxxxxxx"
                                                                :class="{ 'is-invalid': errors.nationalId }"
                                                                class="form-control form-control-sm" id="nationalId"
                                                                v-model="form.nationalId"
                                                                @blur="validateField('nationalId')"
                                                                @focus="clearError('nationalId')" required>
                                                            <div class="invalid-feedback" v-if="errors.nationalId">
                                                                {{ errors.nationalId }}</div>
                                                        </div>
                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="propertyType" class="form-label">{{
        $t('message.PropertyType') }}</label>
                                                            <select v-model="form.propertyType"
                                                                :class="{ 'is-invalid': errors.propertyType }"
                                                                class="form-control form-control-sm" id="propertyType"
                                                                @blur="validateField('propertyType')"
                                                                @focus="clearError('propertyType')" required>
                                                                <option value="" disabled selected>
                                                                    {{ $t('message.selectPropertyType') }}</option>
                                                                <option v-for="property in propertyTypes"
                                                                    :key="property" :value="property">{{ property }}
                                                                </option>
                                                            </select>
                                                            <div class="invalid-feedback" v-if="errors.propertyType">
                                                                {{ errors.propertyType }}</div>
                                                        </div>
                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="blockNumber" class="form-label">{{
        $t('message.PropertyNumber') }}</label>
                                                            <input type="number"
                                                                :placeholder="$t('message.PropertyNumber')"
                                                                :class="{ 'is-invalid': errors.blockNumber }"
                                                                class="form-control form-control-sm" id="blockNumber"
                                                                v-model="form.blockNumber"
                                                                @blur="validateField('blockNumber')"
                                                                @focus="clearError('blockNumber')" required>
                                                            <div class="invalid-feedback" v-if="errors.blockNumber">
                                                                {{ errors.blockNumber }}</div>
                                                        </div>
                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="spaceModal" class="form-label">{{
        $t('message.area') }}</label>
                                                            <input type="text" :placeholder="$t('message.area')"
                                                                :class="{ 'is-invalid': errors.spaceModal }"
                                                                class="form-control form-control-sm" id="spaceModal"
                                                                v-model="form.spaceModal"
                                                                @blur="validateField('spaceModal')"
                                                                @focus="clearError('spaceModal')" required>
                                                            <div class="invalid-feedback" v-if="errors.spaceModal">
                                                                {{ errors.spaceModal }}</div>
                                                        </div>
                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="instrumentNumber" class="form-label">{{
        $t('message.deedNumber') }}</label>
                                                            <input type="number" :placeholder="$t('message.deedNumber')"
                                                                :class="{ 'is-invalid': errors.instrumentNumber }"
                                                                class="form-control form-control-sm"
                                                                id="instrumentNumber" v-model="form.instrumentNumber"
                                                                @blur="validateField('instrumentNumber')"
                                                                @focus="clearError('instrumentNumber')" required>
                                                            <div class="invalid-feedback"
                                                                v-if="errors.instrumentNumber">{{ errors.instrumentNumber
                                                                }}</div>
                                                        </div>
                                                        <div class="mb-3 col-md-12 col-6">
                                                            <label for="sourceInstrument" class="form-label">{{
        $t('message.deedSource') }}</label>
                                                            <input type="number" :placeholder="$t('message.deedSource')"
                                                                :class="{ 'is-invalid': errors.sourceInstrument }"
                                                                class="form-control form-control-sm"
                                                                id="sourceInstrument" v-model="form.sourceInstrument"
                                                                @blur="validateField('sourceInstrument')"
                                                                @focus="clearError('sourceInstrument')" required>
                                                            <div class="invalid-feedback"
                                                                v-if="errors.sourceInstrument">{{ errors.sourceInstrument
                                                                }}</div>
                                                        </div>

                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-white"
                                                            data-bs-dismiss="modal">{{ $t('message.CancelButton')
                                                            }}</button>
                                                        <button type="button" @click="submitModal()"
                                                            :disabled="!isModalValid" class="btn btn-primary">{{
        $t('message.saveAndInsert') }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Ownership percentage inputs -->


                                    <div v-for="(owner, index) in ownershipPercentages" :key="index"
                                        class="mt-2 mb-3 col-md-3 col-6">
                                        <label :for="'ownership' + index">{{ owner.name }} - نسبة التملك</label>
                                        <input type="number" class="form-control" v-model.number="owner.percentage"
                                            @input="adjustRemainingPercentages(index)" :id="'ownership' + index" min="0"
                                            max="100">
                                    </div>




                                    <div v-if="totalPercentage > 100" class="text-danger mt-2">
                                        {{ 'إجمالي النسب المئوية يتجاوز 100٪. يرجى ضبط النسب.' }}
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-start">

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="unitNumber" class="form-label">{{ 'رقم الوحدة العقارية' }}</label>
                                    <input type="text" placeholder='رقم الوحدة العقارية'
                                        :class="{ 'is-invalid': errors.unitNumber }"
                                        class="form-control form-control-sm" id="unitNumber" :disabled="!enableInput" v-model="form.unitNumber"
                                        @blur="validateField('unitNumber')" @focus="clearError('unitNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.unitNumber">{{ errors.unitNumber }}</div>
                                </div>
                                <div class="mb-3 col-md-3 col-6">
                                    <label for="descriptionUnit" class="form-label">{{ 'وصف الوحدة العقارية' }}</label>
                                    <input type="text" placeholder='وصف الوحدة العقارية'
                                        :class="{ 'is-invalid': errors.descriptionUnit }"
                                        class="form-control form-control-sm" id="descriptionUnit" :disabled="!enableInput"
                                        v-model="form.descriptionUnit" @blur="validateField('descriptionUnit')"
                                        @focus="clearError('descriptionUnit')" required>
                                    <div class="invalid-feedback" v-if="errors.descriptionUnit">{{
        errors.descriptionUnit }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="space" class="form-label">{{ $t('message.area') }}</label>
                                    <input type="text" :placeholder="$t('message.area')" :disabled="!enableInput"
                                        :class="{ 'is-invalid': errors.space }" class="form-control form-control-sm"
                                        id="space" v-model="form.space" @blur="validateField('space')"
                                        @focus="clearError('space')" required>
                                    <div class="invalid-feedback" v-if="errors.space">{{ errors.space }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="unitCode" class="form-label">{{ 'كود الوحدة' }}</label>
                                    <input type="number" placeholder='كود الوحدة'
                                        :class="{ 'is-invalid': errors.unitCode }" :disabled="!enableInput" class="form-control form-control-sm"
                                        id="unitCode" v-model="form.unitCode" @blur="validateField('unitCode')"
                                        @focus="clearError('unitCode')" required>
                                    <div class="invalid-feedback" v-if="errors.unitCode">{{ errors.unitCode }}</div>
                                </div>

                                <!-- <div class="mb-3 col-md-3 col-6">
                                    <label for="ownerName1" class="form-label">{{ 'أسم المالك' }}</label>
                                    <input type="text" placeholder='أسم المالك'
                                        :class="{ 'is-invalid': errors.ownerName1 }" class="form-control form-control-sm"
                                        id="ownerName1" v-model="form.ownerName1" @blur="validateField('ownerName1')"
                                        @focus="clearError('ownerName1')" required>
                                    <div class="invalid-feedback" v-if="errors.ownerName1">{{ errors.ownerName1 }}</div>
                                </div> -->

                                <!-- <div class="mb-3 col-md-3 col-6">
                                    <label for="ownershipercentage" class="form-label">{{'نسبه تملكة' }}</label>
                                    <input type="number" placeholder= 'نسبه تملكة'
                                        :class="{ 'is-invalid': errors.ownershipercentage }"
                                        class="form-control form-control-sm" id="ownershipercentage"
                                        v-model="form.ownershipercentage" @blur="validateField('ownershipercentage')"
                                        @focus="clearError('ownershipercentage')" required>
                                    <div class="invalid-feedback" v-if="errors.ownershipercentage">{{errors.ownershipercentage }}</div>
                                </div> -->

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="ownedSpace" class="form-label">{{ 'المساحة المملوكة' }}</label>
                                    <input type="number" placeholder='المساحة المملوكة'
                                        :class="{ 'is-invalid': errors.ownedSpace }" :disabled="!enableInput"
                                        class="form-control form-control-sm" id="ownedSpace" v-model="form.ownedSpace"
                                        @blur="validateField('ownedSpace')" @focus="clearError('ownedSpace')" required>
                                    <div class="invalid-feedback" v-if="errors.ownedSpace">{{ errors.ownedSpace }}</div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="FloorNumber" class="form-label">{{ 'رقم الطابق' }}</label>
                                    <input type="number" placeholder='رقم الطابق'
                                        :class="{ 'is-invalid': errors.FloorNumber }" :disabled="!enableInput"
                                        class="form-control form-control-sm" id="FloorNumber" v-model="form.FloorNumber"
                                        @blur="validateField('FloorNumber')" @focus="clearError('FloorNumber')"
                                        required>
                                    <div class="invalid-feedback" v-if="errors.FloorNumber">{{ errors.FloorNumber }}
                                    </div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="bathroomsNumber" class="form-label">{{ 'عدد الحمامات' }}</label>
                                    <input type="number" placeholder='عدد الحمامات'
                                        :class="{ 'is-invalid': errors.bathroomsNumber }" :disabled="!enableInput"
                                        class="form-control form-control-sm" id="bathroomsNumber"
                                        v-model="form.bathroomsNumber" @blur="validateField('bathroomsNumber')"
                                        @focus="clearError('bathroomsNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.bathroomsNumber">{{
        errors.bathroomsNumber }}
                                    </div>
                                </div>


                                <div class="mb-3 col-md-3 col-6">
                                    <label for="bedroomsNumber" class="form-label">{{ 'عدد غرف النوم' }}</label>
                                    <input type="number" placeholder='عدد غرف النوم'
                                        :class="{ 'is-invalid': errors.bedroomsNumber }" :disabled="!enableInput"
                                        class="form-control form-control-sm" id="bedroomsNumber"
                                        v-model="form.bedroomsNumber" @blur="validateField('bedroomsNumber')"
                                        @focus="clearError('bedroomsNumber')" required>
                                    <div class="invalid-feedback" v-if="errors.bedroomsNumber">{{ errors.bedroomsNumber
                                        }}</div>
                                </div>


                                <div class="mb-3 col-md-3 col-6">
                                    <label for="northBoundary" class="form-label">{{ $t('message.northernBoundary')
                                        }}</label>
                                    <input type="text" :placeholder="$t('message.northernBoundary')"
                                        :class="{ 'is-invalid': errors.northBoundary }" :disabled="!enableInput"
                                        class="form-control form-control-sm" id="northBoundary"
                                        v-model="form.northBoundary" @blur="validateField('northBoundary')"
                                        @focus="clearError('northBoundary')" required>
                                    <div class="invalid-feedback" v-if="errors.northBoundary">{{ errors.northBoundary }}
                                    </div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="southBoundary" class="form-label">{{ $t('message.southernBoundary')
                                        }}</label>
                                    <input type="text" :placeholder="$t('message.southernBoundary')" :disabled="!enableInput"
                                        :class="{ 'is-invalid': errors.southBoundary }"
                                        class="form-control form-control-sm" id="southBoundary"
                                        v-model="form.southBoundary" @blur="validateField('southBoundary')"
                                        @focus="clearError('southBoundary')" required>
                                    <div class="invalid-feedback" v-if="errors.southBoundary">{{ errors.southBoundary }}
                                    </div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="eastBoundary" class="form-label">{{ $t('message.eastBoundary')
                                        }}</label>
                                    <input type="text" :placeholder="$t('message.eastBoundary')"
                                        :class="{ 'is-invalid': errors.eastBoundary }"
                                        :disabled="!enableInput"
                                        class="form-control form-control-sm" id="eastBoundary"
                                        v-model="form.eastBoundary" @blur="validateField('eastBoundary')"
                                        @focus="clearError('eastBoundary')" required>
                                    <div class="invalid-feedback" v-if="errors.eastBoundary">{{ errors.eastBoundary }}
                                    </div>
                                </div>

                                <div class="mb-3 col-md-3 col-6">
                                    <label for="westBoundary" class="form-label">{{ $t('message.westBoundary')
                                        }}</label>
                                    <input type="text" :placeholder="$t('message.westBoundary')" :disabled="!enableInput"
                                        :class="{ 'is-invalid': errors.westBoundary }"
                                        class="form-control form-control-sm" id="westBoundary"
                                        v-model="form.westBoundary" @blur="validateField('westBoundary')"
                                        @focus="clearError('westBoundary')" required>
                                    <div class="invalid-feedback" v-if="errors.westBoundary">{{ errors.westBoundary }}
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>

                </div>
                <div class="action-content">
                    
                <div class="mt-5">
                    <button class="btn btn-primary" @click="toggleEditMode">{{ enableInput ? 'قفل' : 'فك' }}</button>
                </div>
                
                <div class="mt-5">
                    <button class="btn btn-primary" @click="handleSubmit" :disabled="!isStepValid">{{ isEditMode ?
                        $t('message.save') : $t('message.saveAndInsert') }}</button>
                </div>
                
                <div class="mt-5" v-if="isEditMode">
                    <button class="btn btn-primary" @click="goBack">{{$t('message.back') }}</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';



export default {
    props: {
        createUnitId: {
            type: Number,
            required: false
        },
        editUnitId: {
            type: Number,
            required: false
        }
    },
    components: {
        vSelect
    },
    data() {
        return {
            ownershipPercentages: [],
            enableInput: false,
            form: {
                unitNumber: '',
                descriptionUnit: '',
                space: '',
                legalOwnership: [],
                unitCode: '',
                // ownerName1: '',
                bathroomsNumber: '',
                FloorNumber: '',
                bedroomsNumber: '',
                // ownershipercentage: '',
                ownedSpace: '',
                northBoundary: '',
                southBoundary: '',
                eastBoundary: '',
                westBoundary: '',

                // for modal 
                ownerName: '',
                nationalId: '',
                blockNumber: '',
                propertyType: '',
                spaceModal: '',
                instrumentNumber: '',
                sourceInstrument: '',
                //end modal
            },
            managers: [
                'المالك 1',
                'المالك 2',
                'المالك 3',
            ],
            propertyTypes: [
                'شقة بثلاثة غرف',
                'شقة بخمسة غرف',
                'مجمع سكني',
                'فيلا'
            ],
            errors: {
                unitNumber: '',
                descriptionUnit: '',
                space: '',
                legalOwnership: '',
                unitCode: '',
                // ownerName1: '',
                bathroomsNumber: '',
                bedroomsNumber: '',
                FloorNumber: '',
                // ownershipercentage: '',
                ownedSpace: '',
                northBoundary: '',
                southBoundary: '',
                eastBoundary: '',
                westBoundary: '',

                // for modal
                ownerName: '',
                nationalId: '',
                blockNumber: '',
                propertyType: '',
                spaceModal: '',
                instrumentNumber: '',
                sourceInstrument: '',
                //  end modal

            }

        }
    },
    mounted() {
        
        if (this.editUnitId) {
            console.log('send request to get data');
        }
        if (this.isEditMode === false) {
            this.enableInput = true;
        }
    },
    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
        direction() {
            return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
        },
        isModalValid() {
            return this.form.ownerName && this.form.nationalId && this.form.propertyType &&
                this.form.blockNumber && this.form.spaceModal && this.form.instrumentNumber &&
                this.form.sourceInstrument;
        },
        totalPercentage() {
            return this.ownershipPercentages.reduce((total, owner) => total + owner.percentage, 0);
        },
        isStepValid() {
            const step3Fields = [
                'unitNumber',
                'descriptionUnit',
                'space',
                'legalOwnership',
                'unitCode',
                // 'ownerName1',
                'bathroomsNumber',
                'FloorNumber',
                'bedroomsNumber',
                // 'ownershipercentage',
                'ownedSpace',
                'northBoundary',
                'southBoundary',
                'eastBoundary',
                'westBoundary',

            ];
            const step3Errors = step3Fields.some(field => this.errors[field]);

            const step3Filled = step3Fields.every(field => this.form[field] !== '');
            if (this.form.legalOwnership.length == 0) {
                return false;
            }
            // تحقق من أن أي مالك ليس لديه نسبة ملكية 0 أو نسبة ملكية فارغة
            const hasInvalidPercentage = this.ownershipPercentages.some(owner =>
                owner.percentage === 0 || owner.percentage === '' || owner.percentage == null
            );

            if (hasInvalidPercentage) {
                return false;
            }

            // تحقق من أن مجموع النسب المئوية يساوي 100
            const totalPercentage = this.ownershipPercentages.reduce((total, owner) => {
                return total + parseFloat(owner.percentage || 0);
            }, 0);

            if (totalPercentage !== 100) {
                return false;
            }

            return !step3Errors && step3Filled;
        }
    },
    watch: {
        'form.legalOwnership': {
            handler() {
                this.updateOwnershipPercentages();
            },
            deep: true
        },
        ownershipPercentages: {
            handler() {
                // Ensure total does not exceed 100%
                this.ownershipPercentages.forEach((owner, index) => {
                    if (this.totalPercentage > 100) {
                        this.adjustRemainingPercentages(index);
                    }
                });
            },
            deep: true
        },
        editUnitId: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.isEditMode = true;
                    this.fetchPropertyData(newVal);
                } else {
                    this.isEditMode = false;
                    this.resetForm();
                }
            }
        }
    },
    methods: {
        fetchPropertyData(id) {
            // Fetch property data and populate form
        },
        goBack() {
            window.history.back();
        },
        toggleEditMode() {            
            this.enableInput = !this.enableInput;

        },
        resetForm() {
            // Reset form data

        },
        updateOwnershipPercentages() {
            const totalOwners = this.form.legalOwnership.length;

            // Ensure ownershipPercentages array matches the selected owners
            this.ownershipPercentages = this.form.legalOwnership.map((owner, index) => {
                return {
                    name: owner,
                    percentage: this.ownershipPercentages[index]?.percentage || 0
                };
            });
        },
        adjustRemainingPercentages(index) {
            if (this.totalPercentage > 100) {
                this.ownershipPercentages[index].percentage -= (this.totalPercentage - 100);
            }
        },
        validateField(field) {
            if (!this.form[field]) {
                this.$set(this.errors, field, this.$t('message.errorHandle'));
            }
        },
        clearError(field) {
            this.$delete(this.errors, field);
        },
        submitModal() {
            if (this.isModalValid) {
                this.managers.push(this.form.ownerName);
                this.form = {
                    ownerName: '',
                    nationalId: '',
                    blockNumber: '',
                    propertyType: '',
                    spaceModal: '',
                    instrumentNumber: '',
                    sourceInstrument: '',
                }
                $('#addnewusermodel').modal('hide');
            }
        },


        // sendForm(){
        //     this.$nextTick(() => {
        //     this.$router.push('/' + this.$i18n.locale + '/AssociationRealEstate/' + this.associationId);
        //     });
        // }
        handleSubmit() {
            if (this.isEditMode) {
                this.updateProperty();
            } else {
                this.createProperty();
            }
        },
        createProperty() {
            console.log('add new property');
        },
        updateProperty() {
            console.log('edit property');
        }
    },
}
</script>
<style scoped>
.content-card {
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .0);
    margin-top: 80px;
    padding: 15px;
    background-color: #fff;
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: 0.75rem;
    margin-right: 15px;
    margin-left: 15px;
}

.page-header-content {
    padding-right: 20px;
    padding-left: 20px;
}

.page-header-title {
    margin-bottom: .5rem;
}

.page-header-text {
    margin-bottom: .25rem;
    color: #677788;
    font-size: 14px
}

.addUserBtn {
    margin-top: 28px;
    width: -webkit-fill-available;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #4b7ab8;
    --bs-btn-border-color: #4b7ab8;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #3e69a1;
    --bs-btn-hover-border-color: #3e69a1;
    --bs-btn-focus-shadow-rgb: 85, 145, 255;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #3e69a1;
    --bs-btn-active-border-color: #3e69a1;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #3e69a1;
    --bs-btn-disabled-border-color: #3e69a1;
}

#main-content {
    /* max-height: 1200px; */
    min-height: 1100px;
}

.content {
    padding-top: 20px;
    /* padding-left: 10px; */
    /* padding-right: 10px; */
}

.content-rtl {
    margin-right: 17.2rem;
}

.content-ltr {
    margin-left: 17.2rem;
}

.full-content-rtl {
    margin-right: 90px;
    /* Adjusted for closed sidebar */
}

.full-content-ltr {
    margin-left: 85px;
    /* Adjusted for closed sidebar */
}

.stepTwoContent {
    border: 1px solid #ddd;
    border-radius: .3125rem;
    padding: .6125rem 1rem;
    margin-bottom: 15px;
}
.action-content{
    display: flex;
    justify-content: end;
}
.action-content div{
    margin: 5px;
}
</style>