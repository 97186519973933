<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
        <div class="content-card">
            <div class="page-header">
                <div class="row mb-3">
                    <div class="col-sm mb-2 mb-sm-0">
                        <div class="d-flex gap-2 align-items-center mb-3">
                            <h1 class="page-header-title mb-0">{{ 'قائمة الأدوار والصلاحيات' }}</h1>
                        </div>
                        <p class="page-header-text">{{'سوف تجد هنا قائمة بجميع الأدوار والصلاحيات'}}</p>
                    </div>
                </div>
                <!-- End Row -->
            </div>


            <div class="row justify-content-end mb-3" v-if="selectedCount > 0">
                <div class="col-lg">
                    <!-- Datatable Info -->
                    <div id="datatableCounterInfo">
                        <div class="d-sm-flex justify-content-lg-end align-items-sm-center">
                            <span class="d-block d-sm-inline-block fs-5 me-3 mb-2 mb-sm-0">
                                {{$t('message.selectedLists')}} {{ ': ' }}<span id="datatableCounter">{{ selectedCount }}</span>
                            </span>
                            <a class="btn btn-outline-danger btn-sm mb-2 mb-sm-0 me-2" @click="deleteSelected">
                                <i class="bi-trash"></i> {{$t('message.delete')}}
                            </a>
                        </div>
                    </div>
                    <!-- End Datatable Info -->
                </div>
            </div>

            <div class="card">
                <!-- Header -->
                <div class="card-header card-header-content-md-between bg-light jadda-pattern-dark">
                    <div class="mb-md-0 col-md-4">
                        <div class="input-group input-group-merge input-group-flush">
                            <div class="input-group-prepend input-group-text">
                                <lord-icon colors="primary:#121331,secondary:#edd100" target=".input-group"
                                    src="https://cdn.lordicon.com/iszohckt.json" trigger="hover"
                                    style="width:15px;height:15px"> </lord-icon>
                            </div>
                            <b-form-input v-model="filter" :placeholder="'البحث فى قائمة الأدوار والصلاحيات'"
                                class="mt-3 mb-3 form-control bg-white filter-search-input"></b-form-input>
                            <!-- <input id="datatableSearch" type="search" class="form-control bg-white" placeholder="البحث في قائمة الجمعيات" aria-label="Search users"> -->
                        </div>
                    </div>
                    <!-- End Search -->

                    <div class="d-grid d-sm-flex gap-2">
                        <!-- <div class="dropdown mb-2 mb-md-0">
                            <button @click="createNewUser()" class="btn btn-primary add-new-property">{{'إضافه مستخدم'}}</button>
                        </div> -->
                        <div class="dropdown">
                            <button type="button" class="btn btn-white show-lists w-100" id="showHideDropdown"
                                data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                <i class="bi-table me-1"></i> {{ $t('message.lists') }} <span
                                    class="badge bg-soft-dark text-dark rounded-circle ms-1">{{ this.fields.length }}</span>
                            </button>

                            <div class="dropdown-menu dropdown-menu-end dropdown-card"
                                aria-labelledby="showHideDropdown" style="width: 15rem;">
                                <div class="card card-sm">
                                    <div class="card-body">
                                    <div class="d-grid gap-3 w-100">
                                        <b-form-checkbox v-for="field in fields" :disabled="field.key === 'select'"
                                                        :key="field.key" v-model="field.visible">
                                        <span style="margin: 5px">{{ field.label }}</span>
                                        </b-form-checkbox>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Dropdown -->
                    </div>
                </div>

            <b-table hover responsive :items="filteredItems" :fields="visibleFields" :current-page="currentPage" :per-page="perPage"
                    :filter="filter" @filtered="onFiltered" @sort-changed="onSortChanged">

                    <template #head(select)="data">
                        <b-form-checkbox v-model="selectAll" @change="toggleSelectAll"></b-form-checkbox>
                    </template>

                    <template #cell(select)="row">
                        <b-form-checkbox v-model="row.item.selected"></b-form-checkbox>
                    </template>

                    <template #cell(edit)="row">
                        <!-- <b-dropdown size="sm" text="Actions" variant="link">
                        <b-dropdown-item @click="enableEdit(row.item)">Edit</b-dropdown-item>
                        <b-dropdown-item @click="deleteItem(row.item)">Delete</b-dropdown-item>
                    </b-dropdown> -->
                        <div class="btn-group" role="group">
                            <a class="btn btn-white btn-sm" @click="handleEditClick(row.item)">
                                <i class="bi-pencil-fill dropdown-item-icon"></i> {{ ' تعديل' }}
                            </a>
                        </div>
                    </template>

                </b-table>

                <template v-if="filteredItems.length === 0 && filter.length > 0">
                    <div class="text-center p-4">
                        <lord-icon src="https://cdn.lordicon.com/wkppeqfv.json"
                            colors="primary:#121331,secondary:#edd100" trigger="loop" style="width:100px;height:100px">
                        </lord-icon>
                        <p class="mb-0"> {{ $t('message.noMatchingResults') }} "{{ filter }}".</p>
                    </div>
                </template>


                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
            </div>
            <!-- end card -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            items: [
                { id: 1,nameOfRole: 'مسؤل النظام ', numberOfUsers: '5', selected: false, isEditing: false },
                { id: 2,nameOfRole: 'مدير الحساب', numberOfUsers: '5', selected: false, isEditing: false },
                { id: 3,nameOfRole: 'مسؤول حساب',numberOfUsers: '5', selected: false, isEditing: false },
                { id: 4,nameOfRole: 'مستخدم',  numberOfUsers: '5', selected: false, isEditing: false },
                { id: 5,nameOfRole: 'فني',numberOfUsers: '5', selected: false, isEditing: false }
               
                // Add more items here
            ],
            fields: [
                { key: 'select', label: '', visible: true },
                { key: 'id', label: 'id', visible: true },
                { key: 'nameOfRole', label: 'إسم الدور', visible: true },
                { key: 'numberOfUsers', label: 'عدد المستخدمين', visible: true },
                { key: 'edit', label: 'تعديل', visible: true }
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            filter: '',
            selectAll: false,
            inputText: '',
            showError: false,
            isValid: false,
        };
    },
    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
        visibleFields() {
            return this.fields.filter(field => field.visible);
        },
        filteredItems() {
            return this.items.filter(item => {
                return this.visibleFields.some(field => {
                    return item[field.key] && item[field.key].toString().toLowerCase().includes(this.filter.toLowerCase());
                });
            });
        },
        selectedCount() {
            return this.items.filter(item => item.selected).length;
        },
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        // updateVisibleFields() {
        //     this.visibleFields = this.fields.filter(field => field.visible);
        // },
        toggleSelectAll() {
            const startIndex = (this.currentPage - 1) * this.perPage;
            const endIndex = startIndex + this.perPage;

            this.items.slice(startIndex, endIndex).forEach(item => (item.selected = this.selectAll));
        },
        enableDelete(item) {
            this.deleteItem = item; // تخزين العنصر الذي سيتم حذفه
        },
        deleteItemConfirmed() {
            this.items = this.items.filter(i => i.id !== this.deleteItem.id); // حذف العنصر
            this.deleteItem = {}; // إعادة تعيين العنصر المحذوف
            $('#delete-modal').modal('hide'); // إخفاء المودال
        },
        deleteSelected() {
            this.items = this.items.filter(item => !item.selected);
            this.selectAll = false;
        },
        onSortChanged(context) {
            console.log('Sort Changed', context);
        },
        createNewUser() {
            this.$nextTick(() => {
            this.$router.push('/' + this.$i18n.locale + '/AddNewUser');
            });
        },
        handleEditClick(item) {
            this.$nextTick(() => {
            this.$router.push('/' + this.$i18n.locale + '/ModifyUserRole/' + item.id);
            });
        },
        
        validateInput() {
           // إزالة المسافات من النص لحساب الطول بدون المسافات
            const cleanedText = this.inputText.replace(/\s/g, '');
            // Regex يتحقق من أن النص يحتوي فقط على الأحرف الإنجليزية أو العربية
            const regex = /^[\u0600-\u06FFa-zA-Z]+$/;
            this.isValid = regex.test(cleanedText) && cleanedText.length >= 50;
            this.showError = !this.isValid;
        },
    },
    mounted() {
        this.totalRows = this.items.length;
    }
};
</script>

<style scoped>


.bg-light {
    background-color: rgba(249, 250, 252, 1) !important;
    min-height: 100%;
}

.jadda-pattern-dark {
    background-image: url(../assets/pattren-dark.svg);
    background-size: 150px;
}

.card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
    /* color: var(--bs-card-cap-color); */
    /* background-color: var(--bs-card-cap-bg); */
    border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
}

.card-header:first-child {
    border-radius: 0.6875rem 0.6875rem 0 0;
}

.page-header-content {
    padding-right: 20px;
    padding-left: 20px;
}

.page-header-title {
    margin-bottom: .5rem;
}

.page-header-text {
    margin-bottom: .25rem;
    color: #677788;
    font-size: 14px
}

/* .dropdown-menu.show {
    overflow: scroll !important;
    height: 300px !important;
} */
</style>