<template>
    <div>
      <propertyForm :propertyId="parsedPropertyId" />
    </div>
  </template>

<script>

import propertyForm from '../components/propertyForm.vue';


export default {
    props: ['id'],
    components: {
        propertyForm
    },
    data() {
        return {
            
        };
    },
    computed: {
        parsedPropertyId() {
            return parseInt(this.id);
        }
    }
};
</script>