<template>
    <div class="home content" id="main-content" :class="[{ 'content-rtl': isRTL, 'content-ltr': !isRTL }]">
      <div class="content-card">
        <div class="card-header bg-light jadda-pattern-dark">
          <div class="page-header-content">
            <h4 class="page-header-title">{{$t('message.createNewAssociationAccount')}}</h4>
            <p class="page-header-text">{{$t('message.createNewAssociationParagraph')}}</p>
          </div>
        </div>
        <!-- <div class="container"> -->
          <div class="row">
            <div class="col-md-3" :class="[{ 'ps-md-0-rtl': isRTL, 'ps-md-0-ltr': !isRTL }]">
              <div class="stepper-wrapper bg-light">
                <div class="stepper d-flex flex-column">
                  <div v-for="step in steps" :key="step.id"
                    :class="['stepper-item', { 'completed': step.completed, 'active': step.id === currentStep }]">
                    <div class="step-counter">{{ step.id }}</div>
                    <div class="step-name">{{ step.title }}</div>
                    <div class="step-name">{{ step.content }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-9" :class="[{ 'pe-md-0-rtl': isRTL, 'pe-md-0-ltr': !isRTL }]">
              <div class="step-content">
                <div v-if="currentStep === 1">
                  <!-- محتوى الخطوة 1 -->
                  <h5 class="mb-0">{{$t('message.basicInformationAboutAssociation')}}</h5>
                  <p>{{$t('message.manageBasicInformationAboutAssociation')}}</p>
  
                  <div class="mt-5">
                    <div class="row justify-content-start">
  
                      <div v-if="isRTL" class="mb-3 col-md-4 col-6">
                        <label for="contractNameAr" class="form-label">{{$t('الإسم (ar)')}}</label>
                        <input type="text" :placeholder="$t('message.associationName')" :class="{'is-invalid': errors.contractNameAr}" class="form-control form-control-sm" id="contractNameAr" v-model="form.contractNameAr" @blur="validateField('contractNameAr')" @focus="clearError('contractNameAr')" required>
                        <div class="invalid-feedback" v-if="errors.contractNameAr">{{ errors.contractNameAr }}</div>
                      </div>
                      <div v-if="!isRTL" class="mb-3 col-md-4 col-6">
                        <label for="contractNameEn" class="form-label">{{'الإسم (en)'}}</label>
                        <input type="text" :placeholder="$t('message.associationName')" :class="{'is-invalid': errors.contractNameEn}" class="form-control form-control-sm" id="contractNameEn" v-model="form.contractNameEn" @blur="validateField('contractNameEn')" @focus="clearError('contractNameEn')" required>
                        <div class="invalid-feedback" v-if="errors.contractNameEn">{{ errors.contractNameEn }}</div>
                      </div>
  
                      <div class="mb-3 col-md-4 col-6">
                        <label for="contractId" class="form-label">{{$t('message.associationNumber')}}</label>
                        <input type="number" :placeholder="$t('message.associationNumber')" :class="{'is-invalid': errors.contractId}" class="form-control form-control-sm" id="contractId" v-model="form.contractId" @blur="validateField('contractId')" @focus="clearError('contractId')" required>
                        <div class="invalid-feedback" v-if="errors.contractId">{{ errors.contractId }}</div>
                      </div>
                      
                      <div class="mb-3 col-md-4 col-6">
                        <label for="unitNumber" class="form-label">{{$t('message.numberOfUnits')}}</label>
                        <input type="number" :placeholder="$t('message.numberOfUnits')" :class="{'is-invalid': errors.unitNumber}" class="form-control form-control-sm" id="unitNumber" v-model="form.unitNumber" @blur="validateField('unitNumber')" @focus="clearError('unitNumber')" required>
                        <div class="invalid-feedback" v-if="errors.unitNumber">{{ errors.unitNumber }}</div>
                      </div>
                      
                      <div class="mb-3 col-md-4 col-6">
                        <label for="firstApproval" class="form-label">{{$t('message.dateOfFirstApproval')}}</label>
                        <input type="date" :class="{'is-invalid': errors.firstApproval}" class="form-control form-control-sm" id="firstApproval" v-model="form.firstApproval" @blur="validateField('firstApproval')" @focus="clearError('firstApproval')" required>
                        <div class="invalid-feedback" v-if="errors.firstApproval">{{ errors.firstApproval }}</div>
                      </div>
                      
                      <div class="mb-3 col-md-4 col-6">
                        <label for="establishmentDate" class="form-label">{{$t('message.dateOfEstablishment')}}</label>
                        <input type="date" :class="{'is-invalid': errors.establishmentDate}" class="form-control form-control-sm" id="establishmentDate" v-model="form.establishmentDate" @blur="validateField('establishmentDate')" @focus="clearError('establishmentDate')" required>
                        <div class="invalid-feedback" v-if="errors.establishmentDate">{{ errors.establishmentDate }}</div>
                      </div>
                      
                      <div class="mb-3 col-md-4 col-6">
                        <label for="expireDate" class="form-label">{{$t('message.expirationDate')}}</label>
                        <input type="date" :class="{'is-invalid': errors.expireDate}" class="form-control form-control-sm" id="expireDate" v-model="form.expireDate" @blur="validateField('expireDate')" @focus="clearError('expireDate')" required>
                        <div class="invalid-feedback" v-if="errors.expireDate">{{ errors.expireDate }}</div>
                      </div>
                      
                      <div class="mb-3 col-md-4 col-6">
                        <label for="unifiedNumber" class="form-label">{{$t('message.unifiedNumber')}}</label>
                        <input type="number" :placeholder="$t('message.unifiedNumber')" :class="{'is-invalid': errors.unifiedNumber}" class="form-control form-control-sm" id="unifiedNumber" v-model="form.unifiedNumber" @blur="validateField('unifiedNumber')" @focus="clearError('unifiedNumber')" required>
                        <div class="invalid-feedback" v-if="errors.unifiedNumber">{{ errors.unifiedNumber }}</div>
                      </div>
                      <div class="mb-3 col-md-4 col-6">
                        <label for="establishmentNumber" class="form-label">{{$t('message.establishmentNumber')}}</label>
                        <input type="number" :placeholder="$t('message.establishmentNumber')" :class="{'is-invalid': errors.establishmentNumber}" class="form-control form-control-sm" id="establishmentNumber" v-model="form.establishmentNumber" @blur="validateField('establishmentNumber')" @focus="clearError('establishmentNumber')" required>
                        <div class="invalid-feedback" v-if="errors.establishmentNumber">{{ errors.establishmentNumber }}</div>
                      </div>
                    
                    </div>
                  </div>
                </div>
  
                <div v-if="currentStep === 2">
                  <h5 class="mb-0">{{$t('message.associationManagement')}}</h5>
                  <p>{{$t('message.associationManagementOptions')}}</p>
  
                  <div class="mt-3 stepTwoContent">
                  <div class="row">
                    <div class="mb-3 col-md-6 col-12">
                    
                      <label for="manager" class="form-label">{{$t('message.associationManager')}}</label>
                      <v-select
                        :dir="direction"
                        v-model="manager"
                        :options="managers"
                        close-on-select="false"
                        id="manager"
                        :placeholder="$t('message.selectManagerPlaceholder')"
                      ></v-select>
                      
                    </div>
                    <div class="mb-3 col-md-6 col-12">
                      <label for="appointmentDateFrom" class="form-label">{{$t('message.dateOfAppointmentFrom')}}</label>
                      <input type="date" class="form-control form-control-sm" id="appointmentDateFrom" v-model="appointmentDateFrom" required>
                    </div>
                    <div class="mb-3 col-md-6 col-12">
                      <label for="appointmentDateTo" class="form-label">{{$t('message.dateOfAppointmentTo')}}</label>
                      <input type="date" class="form-control form-control-sm" id="appointmentDateTo" v-model="appointmentDateTo" required>
                    </div>
                    <div class="mb-3 col-md-6 col-12">
                      <label for="salary" class="form-label">{{$t('message.salary')}}</label>
                      <input type="number" :placeholder="$t('message.salary')" class="form-control form-control-sm" id="salary" v-model="salary" required>
                    </div>
                    <div class="mb-3 col-md-6 col-12">
                      <label class="form-label">{{$t('message.additionalCommissions')}}</label>
                      <div class="commissions-content">
                        <input type="radio" id="commissionsYes" value="yes" v-model="hasCommissions" @change="toggleCommission">
                        <label class="commissions" for="commissionsYes">{{$t('message.yes')}}</label>
                        <input type="radio" id="commissionsNo" value="no" v-model="hasCommissions" @change="toggleCommission">
                        <label class="commissions" for="commissionsNo">{{$t('message.no')}}</label>
                      </div>
                    </div>
                    <div class="mb-3 col-md-6 col-12" v-if="hasCommissions === 'yes'">
                      <label for="commissionName" class="form-label">{{$t('message.nameOfCommissionOrSalary')}}</label>
                      <input type="text" :placeholder="$t('message.nameOfCommissionOrSalary')" class="form-control form-control-sm" id="commissionName" v-model="commissionName">
                    </div>
                    <div class="mb-3 col-md-6 col-12" v-if="hasCommissions === 'yes'">
                      <label for="commissionType" class="form-label">{{$t('message.typeOfCommission')}}</label>
                      <select v-model="commissionType" class="form-control form-control-sm" id="commissionType" @change="toggleCommissionType">
                        <option value="" disabled selected>{{$t('message.selectTypeOfCommission')}}</option>
                        <option value="fixed">{{$t('message.fixedValue')}}</option>
                        <option value="percentage">{{$t('message.percentageRate')}}</option>
                      </select>
                    </div>
                    <div class="mb-3 col-md-6 col-12" v-if=" hasCommissions === 'yes'">
                      <label v-if="commissionType === 'fixed'" for="commissionValue" class="form-label">{{$t('message.value')}}</label>
                      <label v-if="commissionType !== 'fixed'" for="commissionValue" class="form-label">{{$t('message.percentage')}}</label>
                      <input type="number" :placeholder="commissionType === 'fixed' ? $t('message.value') : $t('message.percentage')" class="form-control form-control-sm" id="commissionValue" v-model="commissionValue">
                    </div>
                  </div>
                </div>
                <h5 class="mb-0 mt-5">{{$t('message.associationFacilitiesManagement')}}</h5>
                              <p>{{$t('message.defineTheAdministrativeModel')}}</p>
                              <div class="input-group input-group-vertical">
                                
                                <label class="form-control" for="allroles">
                                    <span class="form-check">
                                    <input type="radio" class="form-check-input" name="accadminrols" id="allroles" value="1" v-model="selectedRoleId">
                                    <span class="form-check-label">
                                        <h5 class="mb-0">{{ $t('message.comprehensiveFacilitiesManagement') }}</h5>
                                        <p class="mb-0 fs-6">{{ $t('message.comprehensiveFacilitiesManagementParagraph') }}</p>
                                    </span>
                                    </span>
                                </label>
                                <label class="form-control" for="semiroles">
                                    <span class="form-check">
                                    <input type="radio" class="form-check-input" name="accadminrols" id="semiroles" value="2" v-model="selectedRoleId">
                                    <span class="form-check-label">
                                        <h5 class="mb-0">{{ $t('message.integratedFacilitiesManagement') }}</h5>
                                        <p class="mb-0 fs-6">{{ $t('message.integratedFacilitiesManagementParagraph') }}</p>
                                    </span>
                                    </span>
                                </label>
                                <label class="form-control" for="primaryroles">
                                    <span class="form-check">
                                    <input type="radio" class="form-check-input" name="accadminrols" id="primaryroles" value="3" v-model="selectedRoleId">
                                    <span class="form-check-label">
                                        <h5 class="mb-0">{{ $t('message.basicModel') }}</h5>
                                        <p class="mb-0 fs-6">{{ $t('message.basicModelParagraph') }}</p>
                                    </span>
                                    </span>
                                </label>
                                <label class="form-control" for="indivedialroles">
                                    <span class="form-check">
                                    <input type="radio" class="form-check-input" name="accadminrols" id="indivedialroles" value="4" v-model="selectedRoleId">
                                    <span class="form-check-label">
                                        <h5 class="mb-0">{{ $t('message.selfOperation') }}</h5>
                                        <p class="mb-0 fs-6">{{ $t('message.selfOperationParagraph') }}</p>
                                    </span>
                                    </span>
                                </label>
                                </div>

                </div>
               
                <div v-if="currentStep === 3">
                  
                  <h5 class="mb-0">{{$t('message.attachments')}}</h5>
                  <p>{{$t('message.attachmentsParagraph')}}</p>
  
                  <div class="mb-5">
                    <!-- <label for="attachFilesNewProjectLabel"
                      class="col-form-label form-label">صور
                      العمارة</label> -->
                    <div id="attachFilesNewProjectLabel"
                      class="js-dropzone dz-dropzone dz-dropzone-card mb-4">
                      <div class="dz-message">
                        <h5>{{$t('message.dragAndDrop')}}
                        </h5>
                        <p class="mb-2">{{$t('message.or')}}</p>
                        <span class="btn btn-white btn-sm" @click="triggerFileInput">{{$t('message.chooseFiles')}}</span>
  
                          <div class="uploaded-files">
                            <div v-for="(file, index) in files" :key="index" class="file-item col-md-3">
                              <div style="text-align: center;" v-if="file.type.includes('image')">
                                <img :src="file.url" class="uploaded-image" />
                                <div style="text-align: center;">{{ file.name }}</div>
                              </div>
                              <div v-else>
                                <span>{{ file.name }}</span>
                              </div>
                              <progress class="progress-bar bg-success" :value="file.progress" max="100"></progress>
                              <span>{{ (file.size / 1024).toFixed(2) }} KB</span>
                              <button @click="removeFile(index)" class="btn-sm">X</button>
                            </div>
                          </div>
  
                      </div>
                    </div>
                  </div>
  
                  <input 
                    type="file" 
                    ref="fileInput" 
                    @change="handleFilesUpload" 
                    multiple 
                    style="display: none;" 
                  />
  
                  
                </div>
  
              </div>
                <div class="d-flex justify-content-between mt-3 action-content">
                  <button class="btn btn-ghost-secondary" @click="prevStep" :disabled="currentStep === 1"><i v-if="isRTL" class="bi-chevron-right small"></i><i v-else class="bi-chevron-left small"></i> {{$t('message.previous')}} </button>
                  <button v-if="this.currentStep < this.steps.length" class="btn btn-logo" @click="nextStep" :disabled="!isStepValid">{{$t('message.next')}} <i v-if="isRTL" class="bi-chevron-left small"></i><i v-else class="bi-chevron-right small"></i></button>
                  <button v-else class="btn btn-logo" @click="sendForm()" :disabled="!isStepValid">{{$t('message.saveAndInsert')}}</button>
                </div>
            </div>
        
            </div>
          </div>
        <!-- </div> -->
  
  
  
      </div>
  </template>
  
  <script>
  
  import vSelect from 'vue-select';
  import 'vue-select/dist/vue-select.css';
  import axiosInstance from '../axios-config';
  
  export default {
    name: 'AddAssociations',
    props: ['id'],
    components: {
      // LMap,
      // LTileLayer,
      // LMarker,
      vSelect
    },
    data() {
      return {
        currentStep: 1,
        files: [],
        selectedRoleId: null,
        // center: [24.7136, 46.6753], // موقع الرياض
        // markerPosition: [24.7136, 46.6753],
        // lat: 24.7136,
        // lng: 46.6753,
        // zoom: 10,
        steps: [
          { id: 1, title: this.$t('message.information'), content: this.$t('message.basicInformation'), completed: false },
          { id: 2, title: this.$t('message.associationManagement'), content: this.$t('message.managementOptionsContent'), completed: false },
          // { id: 3, title: this.$t('message.propertyDetails'), content: this.$t('message.detailOptions'), completed: false },
          { id: 3, title: this.$t('message.attachments'), content: this.$t('message.attachmentsOptionsContent'), completed: false },
        ],
        form: {
          contractNameAr: '',
          contractNameEn: '',
          contractId: '',
          unitNumber: '',
          establishmentDate: '',
          expireDate: '',
          firstApproval: '',
          unifiedNumber: '',
          establishmentNumber: '',
          
  
        },
        manager: '',
        appointmentDateFrom: '',
        appointmentDateTo: '',
        salary: '',
        hasCommissions: '',
        commissionName: '',
        commissionType: '',
        commissionValue: '',
        managers: [
          
        ],
        propertyTypes: [
          'شقة بثلاثة غرف',
          'شقة بخمسة غرف',
          'مجمع سكني',
          'فيلا'
        ],
        errors: {
         
          
        }
      }
    },
    computed: {
      isRTL() {
        return this.$i18n.locale === 'ar';
      },
      direction() {
        return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
      },
      isModalValid() {
        return this.form.ownerName && this.form.nationalId && this.form.propertyType &&
              this.form.blockNumber && this.form.spaceModal && this.form.instrumentNumber &&
              this.form.sourceInstrument;
      },
      isStepValid() {
        // تحقق من صحة الخطوة الحالية
        if (this.currentStep === 1) {
          const step1Fields = [
            'contractNameAr',
            // 'contractNameEn',
            'contractId',
            'unitNumber',
            'establishmentDate',
            'expireDate',
            'firstApproval',
            'unifiedNumber',
            'establishmentNumber',
          ];
          // تحقق من عدم وجود أخطاء في الحقول الخاصة بالخطوة 1
          const step1Errors = step1Fields.some(field => this.errors[field]);
          
          // تحقق من ملء جميع الحقول الخاصة بالخطوة 1
          const step1Filled = step1Fields.every(field => this.form[field] !== '');
          
          return !step1Errors && step1Filled;
          
          // return Object.keys(this.errors).length === 0 && Object.values(this.form).every(value => value !== '');
        }
        // تحقق صحة الخطوة 2
        if (this.currentStep === 2) {
          if (!this.manager || !this.appointmentDateFrom || !this.appointmentDateTo || !this.salary || !this.hasCommissions) {
            return false;
          }
          if (this.hasCommissions === 'yes' && !this.commissionName) {
            return false;
          }
          if (this.commissionType && !this.commissionValue) {
            return false;
          }
        }
  
        // تحقق صحة الخطوة 3
        // if (this.currentStep === 3) {
        //   const step3Fields = [
        //   'street',
        //   'space',
        //   'flatArea',
        //   'legalOwnership',
        //   'plotNumber',
        //   'bankNumber',
        //   'elevatorCount',
        //   'floorCount',
        //   'constructionYear',
        //   'constructionType',
        //   'instrumentNumber1',
        //   'sourceInstrument1',
        //   'northBoundary',
        //   'southBoundary',
        //   'eastBoundary',
        //   'westBoundary',
        //   'electricityAccountNumber',
        //   'electricityNumber',
        //   'subscriptionElectricityNumber',
        //   'waterAccountNumber',
        //   'WaterNumber',
        //   ];
        //   const step3Errors = step3Fields.some(field => this.errors[field]);
          
        //   const step3Filled = step3Fields.every(field => this.form[field] !== '');
        //   if (this.form.legalOwnership.length == 0) {
        //     return false;
        //   }
          
        //   return !step3Errors && step3Filled;
          
        // }
  
        return true;
      }
    },
    created() {
      this.fetchdata();
      this.getManagers();
    },
    methods: {
      goToStep(step) {
        if (step <= this.currentStep) {
          this.currentStep = step;
        }
      },
      nextStep() {
        if (this.currentStep < this.steps.length) {
          this.steps[this.currentStep - 1].completed = true;
          this.currentStep++;
        }
        else if(this.currentStep == this.steps.length){
          console.log('done');
        }
      },
      prevStep() {
        if (this.currentStep > 1) {
          this.currentStep--;
        }
      },
      validateField(field) {
        if (!this.form[field]) {
          this.$set(this.errors, field, this.$t('message.errorHandle'));
        }
      },
      clearError(field) {
        this.$delete(this.errors, field);
      },
      toggleCommission() {
        if (this.form.hasCommissions === 'no') {
          this.form.commissionName = '';
        }
      },
      toggleCommissionType() {
        this.form.commissionValue = '';
      },
      submitModal(){
        if (this.isModalValid) {
          this.managers.push(this.form.ownerName);
          this.form = {
            ownerName: '',
            nationalId: '',
            blockNumber: '',
            propertyType: '',
            spaceModal: '',
            instrumentNumber: '',
            sourceInstrument: '',
          }
          $('#addnewusermodel').modal('hide');
        }
      },
      updateLatLngFromMarker(latLng) {
        this.lat = latLng.lat;
        this.lng = latLng.lng;
        this.markerPosition = [this.lat, this.lng];
      },
      updateLatLngFromMap(center) {
        this.center = center;
      },
      updateMarkerPosition() {
        this.markerPosition = [parseFloat(this.lat), parseFloat(this.lng)];
        this.center = [...this.markerPosition]; // لتحديث المركز عند تحديث marker
      },
  
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      handleFilesUpload(event) {
        const selectedFiles = Array.from(event.target.files);
        selectedFiles.forEach(file => {
          this.uploadFile(file);
        });
      },
      uploadFile(file) {
        const reader = new FileReader();
        const fileData = {
          name: file.name,
          type: file.type,
          size: file.size,
          progress: 0,
          url: ''
        };
        reader.onload = (e) => {
          fileData.url = e.target.result;
        };
        reader.readAsDataURL(file);
        
        // محاكاة عملية الرفع وإظهار شريط التقدم
        const fakeUpload = setInterval(() => {
          if (fileData.progress >= 100) {
            clearInterval(fakeUpload);
          } else {
            fileData.progress += 10;
          }
        }, 100);
        
        this.files.push(fileData);
      },
      removeFile(index) {
        this.files.splice(index, 1);
      },
      
      markAllStepsUnCompleted() {
        this.steps = this.steps.map(step => ({ ...step, completed: false }));
      },

      async getManagers() {

            try {

            const response = await axiosInstance.get('users',{
                params: {
                    role: 'manager',
                }
            });
                console.log('Response data:', response.data.data);

                // this.managers = response.data.data;

                this.managers = response.data.data.map(role => ({
                    label: role.name, // وضع الاسم كاسم العرض
                    value: role.id // استخدام المعرف كالقيمة
                }));
                
                

            } catch (error) {
                console.error('error:', error.response.data.message);
                // Check if the error has a response and show the backend message
                const errorMessage = error.response?.data?.message || 'An error occurred while trying to get data';
                
                this.$izitoast.error({
                    title: 'Error',
                    message: errorMessage,
                    position: 'bottomLeft',
                    timeout: 5000,
                });

            }
        },

      async fetchdata() {
        
        try {

            let url = 'associations';

            if (this.id) {
                url += `/${this.id}`;
            }

            const response = await axiosInstance.get(url);

            console.log('Response data:', response.data.data);

            if (this.$i18n.locale === 'ar') {
                this.form.contractNameAr = response.data.data.name;
            }else{
                this.form.contractNameEn = response.data.data.name;
            }
            this.form.contractId = response.data.data.association_number;
            this.form.unitNumber = response.data.data.units_number;
            this.form.firstApproval = response.data.data.first_approval_date;
            this.form.establishmentDate = response.data.data.founded_at;
            this.form.expireDate = response.data.data.expired_at;
            this.form.unifiedNumber = response.data.data.unified_number;
            this.form.establishmentNumber = response.data.data.founding_number;
            this.selectedRoleId = response.data.data.association_management_form.id;
            this.manager = response.data.data.manager.name;
            this.appointmentDateFrom = response.data.data.hiring_date_from;
            this.appointmentDateTo = response.data.data.hiring_date_to;
            this.salary = response.data.data.manager_salary;
            this.commissionName = response.data.data.commission_description;
            this.commissionType = response.data.data.commission_type;
            this.commissionValue = response.data.data.commission_value;
           
            
            if (response.data.data.commission_value !== null) {
                
                this.hasCommissions = 'yes';
            }else{
              this.hasCommissions = 'no';
            }
            

        } catch (error) {
            console.error('error:', error);
            this.$izitoast.error({
                title: 'Error',
                message: 'An error occurred while trying to get data',
                position: 'bottomLeft',
                timeout: 5000,
            });

        }
    },
      
        async editAssociations() {
  
         
  
  
          try {
            let url = 'associations';

            if (this.id) {
                url += `/${this.id}`;
            }

              // Basic association data to be sent in the request
              const associationData = {
                'name[ar]': this.form.contractNameAr,
                'name[en]': this.form.contractNameEn,
                'association_number': this.form.contractId,
                'units_number': this.form.unitNumber,
                'first_approval_date': this.form.firstApproval,
                'founded_at': this.form.establishmentDate,
                'expired_at': this.form.expireDate,
                'unified_number': this.form.unifiedNumber,
                'founding_number': this.form.establishmentNumber,
                'association_management_form_id': this.selectedRoleId,
                'manager_id': this.manager.value,
                'hiring_date_from': this.appointmentDateFrom,
                'hiring_date_to': this.appointmentDateTo,
                'manager_salary': this.salary,
                'other_commissions': this.hasCommissions,
                'files': this.files,
              };

              // Conditionally add commission data if `hasCommissions` is 'yes'
              if (this.hasCommissions == 'yes') {
                associationData['commission_description'] = this.commissionName;
                associationData['commission_type'] = this.commissionType;
                associationData['commission_value'] = this.commissionValue;
              }

              const response = await axiosInstance.put(url,associationData);
  
  
              console.log('Response data:', response.data.data);
  
               // Check if the error has a response and show the backend message
               const message = response.data?.message || 'successfully add associations';
              
  
              this.$izitoast.success({
                  title: 'Success',
                  message: message,
                  position: 'bottomLeft',
                  timeout: 5000, // يمكنك تخصيص مدة العرض
              });
  
  
              // التحقق إذا كان المستخدم موجود بالفعل على نفس الصفحة قبل إعادة التوجيه
              const locale = this.$i18n.locale;
              const targetRoute = '/' + locale + '/AssociationsList';
  
              if (this.$route.path !== targetRoute) {
  
                  this.$router.push(targetRoute);
  
              }
  
          } catch (error) {
              console.error('Add user error:', error);
  
              // Check if the error has a response and show the backend message
              const errorMessage = error.response?.data?.message || 'An error occurred while trying to add the new associations';
              
              this.$izitoast.error({
                  title: 'Error',
                  message: errorMessage,
                  position: 'bottomLeft',
                  timeout: 5000,
              });
  
          }
          },
      sendForm(){
        this.editAssociations();
      }
    }
    
  }
  </script>
  
  
  <style scoped>
  .content-card {
    box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .075);
    margin-top: 80px;
    padding: 0px;
    background-color: #fff;
    background-clip: border-box;
    border: 0.0625rem solid rgba(231, 234, 243, 0.7);
    border-radius: 0.75rem;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 2rem
  }
  
  
  .stepper-wrapper {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 20px; */
    /* padding-right: 1rem; */
    padding-top: 35px;
    /* padding-bottom: 25px; */
  }
  .step-content{
    padding: 20px 15px 15px;
    padding-top: 35px;
  }
  .action-content{
    padding: 20px 15px 15px;
  }
  
  .stepper {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
  }
  
  .stepper-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
  }
  
  .stepper-item::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    height: 20px;
    width: 4px;
    background: #ddd;
    transform: translateX(-50%);
  }
  
  .stepper-item:last-child::after {
    display: none;
  }
  
  .stepper-item.active .step-counter,
  .stepper-item.completed .step-counter {
    background: #0d6efd;
    color: white;
  }
  
  .step-counter {
    width: 2.40625rem;
    height: 2.40625rem;
    background: #ddd;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .step-name {
    font-size: 14px;
    text-align: center;
  }
  .stepper-item.active{
    color:#0d6efd;
  }
  
  .form-select {
    width: 70%;
    margin-left: 5px;
  }
  .form-select-rtl{
    background-position: left 0.75rem center;
  }
  .jadda-pattern-dark {
      background-image: url(../assets/pattren-dark.svg);
      background-size: 150px;
  }
  .card-header {
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-bottom: 0;
      /* color: var(--bs-card-cap-color); */
      /* background-color: var(--bs-card-cap-bg); */
      border-bottom:  0.0625rem solid rgba(231, 234, 243, 0.7) !important;
  }
  .card-header:first-child{
    border-radius: 0.6875rem 0.6875rem 0 0;
  }
  .page-header-content{
    padding-right: 20px;
    padding-left: 20px;
  }
  .page-header-title {
      margin-bottom: .5rem;
  }
  .page-header-text {
      margin-bottom: .25rem;
      color:#677788;
      font-size:14px
  }
  
  .h4, h4 {
      font-size: .984375rem;
  }
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
      margin-top: 0;
      /* margin-bottom: .5rem; */
      font-weight: 600;
      line-height: 1.2;
      color: #1E202C;
  }
  .h5, h5 {
      font-size: .875rem;
  }
  .bg-light {
      background-color: rgba(249, 250, 252, 1) !important;
      min-height: 100%;
  }
  .form-label{
    padding-top: .675rem;
      padding-bottom: .675rem;
      margin-bottom: 0;
      font-size: inherit;
      line-height: 1.5;
      color: #1e2022;
  }
  .form-control {
      border: 1px solid #d0d7db;
  }
  .form-control-sm {
      min-height: calc(1.5em + 1.125rem);
      padding: .5rem 1rem;
      font-size: .8125rem;
      border-radius: .3125rem;
  }
  .text-danger {
    color: #ff5252 !important;
    margin-top: 5px;
    font-size: 12px;
  }
  .is-invalid {
    border-color: #ff5252;
  }
  
  .invalid-feedback {
    color: #dc3545;
  }
  .commissions{
    margin-top:0px;
    margin-bottom:0px;
    margin-right:10px;
    margin-left:10px;
  }
  .commissions-content{
    margin-top: 12px;
  }
  .stepTwoContent{
    border: 1px solid #ddd;
    border-radius:.3125rem;
    padding: .6125rem 1rem;
  }
  .addUserBtn{
    margin-top: 42px;
    width: -webkit-fill-available;
  }
  .modal-header {
      padding-bottom: 25px;
      background-image: url(../assets/pattren-dark.svg);
      background-size: 150px;
      background-color: #f9fafc;
  }
  .btn-close{
    font-size:smaller;
  }
  .uploaded-files {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 15px;
    justify-content: center;
  }
  .file-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #dee2e6;
  padding: 7px;
    border-radius: 5px;
    background-color: #f8f9fa;
    width: 22%;
    justify-content: center;
    min-height: 165px;
  }
  .uploaded-image {
    width: 100px;
    /* width: -webkit-fill-available; */
    height: 90px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  .file-item progress {
    width: 100%;
    margin: 5px 0;
    height: 5px;
    border-radius: 50px;
  }
  .file-item button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    padding: initial;
  }
  .ps-md-0-rtl{
    padding-left: 0 !important;
  }
  .ps-md-0-ltr{
    padding-right: 0 !important;
  }
  .pe-md-0-rtl{
    padding-right: 0 !important;
  }
  .pe-md-0-ltr{
    padding-left: 0 !important;
  }
  
  @media(max-width:1024px) {
    .ps-md-0-ltr , .pe-md-0-rtl{
        padding-right: calc(1.5rem * .5) !important;
    }
    .ps-md-0-rtl , .pe-md-0-ltr{
      padding-left: calc(1.5rem * .5) !important;
    }
  }
  
  
  </style>